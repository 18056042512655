@import url(../../App.css);

.why-we-page-container {
    /* background: url("../../assets/images/WhyHome.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 20em; */
    padding-left: var(--nonActiveNavWidth);
    background-attachment: scroll;
}

.why-we-heading {
    margin-top: 8em;
    margin-left: 4em;
}

.why-we-content-text {
    margin-top: 2em;
    width: 68vh;
    margin-left: 4em;
}
.whywe-para{
    margin-bottom: 0 !important;
    font-weight: bold;
    font-family: var(--gothamLight);
}
.col-md-6{
    width: 50% !important;
}

.local-down-arrow-image-class {
    margin-left: 9em;
    margin-top: 2em;
}

.sec2-container {
    padding: 3em 0 12em;
}

.sec2-heading {
    text-align: center;
}

.sec2-heading-sub-text {
    margin-top: 1em;
    font-size: 20px;
    color: var(--white);
    font-family: var(--gothamMedium);
}

.graph-details-container {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
}

.graph-left-cotnainer {
    width: 60%;
    text-align: center;
    padding: 118px 0;
}

.graph-right-cotnainer {
    width: 40%;
}

.sec2-card-heading {
    font-size: 20px;
    color: var(--white);
    font-family: var(--gothamBold);
    margin: unset;
    color: var(--white);
}

.sec2-card-subtext {
    font-family: var(--gothamLight);
    color: var(--white);
    font-size: var(--pageSubHeading);
}

.sec3-right-container {
    max-width: 23em;
}

.sec3-left-contianer{
    height: 25em;
    overflow-y: scroll;
}
#scrollbar::-webkit-scrollbar {
	width: 6px;
}

.scroll-view-card-container {
    margin-left: 3em;
    /* border-left: 8px solid var(--white); */
    height: 25em;
    display: flex;
}

.white-line-container {
    height: 100%;
    width: 8px;
    background-color: var(--white);
}

.scroll-view-content-container {
    margin-left: -1.5em;
    padding-top: 5em;
}

.card-dot-image {
    width: 45px;
    height: 45px;
    position: relative
}

.scroll-view-text-xontent-container {
    margin-top: -55px;
}

.scroll-view-heading {
    font-size: 20px;
    font-family: var(--gothamBold);
    color: var(--white);
    border-bottom: 3px solid var(--white);
    display: inline-block;
    width: auto;
    text-align: center;
    margin: auto;
    padding: 0px 0px 0px 3em
}

.scroll-card-shadow-card-container {
    margin-left: 2em;
    margin-top: 1em;
    cursor: pointer;
}

.top-borders-round {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.bottom-borders-round {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.whywe-animated {
    position: absolute !important;
    background: url('../../assets/images/Blueelipseto2.png') no-repeat !important;
    background-position: center;
    background-size: contain;
    width: 318px !important;
    top: 63em !important;
    left: 75em !important;
    /* animation-name: slidein;
    animation-duration: 1s; */
    z-index: 0;

}
/* @keyframes slidein {
    from {
        left: 2000px;
        top: -300px;
    }

    to {
        left: -200px;
        top: 100px;
    }
} */

.why-we-heading-mbl{
    display: none;
}
.mbl-heading{
    display: none;
}
.background-vector{
    position: absolute;
}
.face-vector{
    position: relative;
    top: 1em;
    left: 20px;
}
.line-dot{
    border-bottom: 3px solid white;
    width: 150px;
    margin-left: 327px;
    margin-top: 80px;
    transform: rotate(117deg);
}
.line-dot::after{
    content: '.';
    font-size: 160px;
    position: absolute;
    margin-top: -177px;
    color: white;
    margin-left: 64px;
    
}



.line-dot-sec{
    border-bottom: 3px solid white;
    width: 150px;
    margin-left: 247px;
    margin-top: -235px;
    transform: rotate(219deg);
}
.line-dot-sec::after{
    content: '.';
    font-size: 160px;
    position: absolute;
    margin-top: -177px;
    color: white;
    margin-left: 64px;
}
.bullet-para{
    font-family: var(--gothamLight);
    font-weight: bold;
}
.bullet-desc{
    margin-bottom: 20px;
}
.dummyimage{
    width: 75%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.whywe-right-half{
    position: absolute;
    right: 0px;
    margin-top: -280px;
    z-index: 999;
}
.col-blue-subhead{
    font-family: var(--gothamLight);
    font-weight: 600;
    padding-top: 1em;
}
.dotted-image{
    position: absolute;
    width: 1115px;
    margin-top: -17em;
    opacity: 0.3;
    margin-left: -8em;
    z-index: -1;
}
.bg-dotted{
    position: relative;
}

.nodegraph-whywe {
    margin-top: 10rem;
    z-index: 999;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .why-we-page-container {
        padding: unset;
    }
    .why-we-heading {
        margin-top: unset;
    }
    .why-we-content-text{
        width: unset;
    }
    .local-down-arrow-image-class {
        margin-left: 1em;
    }
    .sec2-container {
        padding: 30px 40px;
    }
    .sec2-heading {
        text-align: left;
    }
    .graph-details-container {
        display: flex;
        flex-direction: column;
    }
    .graph-right-cotnainer {
        width: 100%;
    }
    .why-we-heading{
        display: none;
    }
    .why-we-heading-mbl{
        display: unset;
    }
    .whywe-para{
        width: 200px;
    }
    .sec2-heading{
        display: none;
    } 
    .mbl-heading{
        display: unset;
    }
    .whywe-dot{
        margin-top: unset !important;
    }
    .whywe-animated{
        display: none !important;
    }
    .whywe-right{
        position: absolute;
        left: 12em;
    }
    .scroll-view-card-container {
        margin-left: 0em;
        /* border-left: 8px solid var(--white); */
    }
}