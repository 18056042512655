.banner-bottom-half-cicle-imgae {
    /* position: absolute; */
    right: 0;
    z-index: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    transform: rotate(0deg);
    padding-left: 67em;
    display: none;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .banner-bottom-half-cicle-imgae  {
        margin-top: -12em;
        width: 200px;
        margin-left: 120px;
        margin-right: auto;
        display: unset;
        padding: unset;
        transform: unset;
    }
}