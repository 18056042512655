@import url(../../App.css);

.react-player__preview{
    border-radius: 20px;
}

.video-player-contianer-com-contianer{
    /* padding-left: var(--nonActiveNavWidth); */
    padding-top: 4em;
    padding-bottom: 12em;
    /* padding-right: 2em; */
}

.video-player-contianer{
    text-align: center;
    margin-top: 6em;
    position: relative;
    z-index: 999;
}

.play-icon{
    width: 70px;
}

.video-player-contianer video{
    border-radius: 20px;
}

.video-heading-continaer{
    display: flex;
    justify-content: center;    
    column-gap: 4em;
}

.video-heading{
    text-align: center;
}

.v-h-l-border{
    height: 3px;
    width: 28em;
    background-color: var(--white);
}

.video-heading-border-contianer{
    margin: auto 0;
}
.sf-head{
    font-size: 32px;
}
.video-bg-line{
    position: relative;
    height: 1px;
    background-color: var(--white);
    top: 19em;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .videoplayer{
        width: unset !important;
        height: 200px !important;
    }
    .v-h-l-border{
        display: none;
    }
    .video-player-contianer-com-contianer{
        padding: 30px 40px;
    } 
    .video-heading{
        font-size: 23px;
    } 
    .video-heading-continaer{
        display: unset;
    }
}