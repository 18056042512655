@import url(../../App.css);

.s-t-p-banner-page {
    overflow: hidden;
    position: relative;
    padding-left: 18em;
    /* max-width: 82em; */
    margin: auto;
}

.s-t-banner-keyboard-contianer {
    position: absolute;
    right: -10em;
    top: 169px;
    transform: rotate(-14deg);
    z-index: 0;
}

.s-t-benner-heading-container {
    padding-top: 2em;
    padding-bottom: 14em;
    z-index: 1;
    position: relative;
}
.st-heading{
    padding-top: 2em;
    padding-left: 80px;
    font-family: var(--gothamLight);
}
.st-main-head{
    padding-left: 80px;
    position: relative;
    z-index: 9999;
}

.CharacterImageContainer {
    width: 50px;
    position: relative;
    text-align: center
}

.keyboard-character-container {
    position: absolute;
    bottom: 21px;
    left: 31px;
    color: var(--white);
    font-family: var(--gothamMedium);
    font-size: 24px;
    text-transform: uppercase;
}

.imageWithCharacterContianer{
    margin-top: -10em;
    margin-left: 27em;
}

.st-sec-2-cotianer{
    padding-top: 6em;
    padding-bottom: 4em;
    padding-right: 5em;
    padding-left: var(--nonActiveNavWidth);
    display: flex;
    column-gap: 6em;
}

.st-sec-2-left-container{
    width: 50%;
}

.st-sec-2-right-container{
    width: 50%;
    position: relative;
}

.ss-sec-2-left-image{
    width: 100%;
}

.st-sec-2-right-content{
    max-width: 400px;
}

.st-sec-2-para-contianer{
    margin-top: 3em;
    width: 425px;
}
.st-sec-2-heading-contianer{
    padding-top: 4em;
}
.st-form{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    padding-top: 2em;
    position: relative;
    z-index: 999;
}
.st-subhead{
    font-size: 45px;
}
.st-cricle{
    position: absolute;
    right: 0;
    margin-top: -32em;
    opacity: 0.3;
    transform: rotate(0deg);
    overflow: hidden;
    width: 810px;
    height: 1078px;
}
.st-footer-cricle{
    position: absolute;
    right: 0;
    margin-top: -32em;
    opacity: 0.6;
    transform: rotate(0deg);
    width: 305px;
}
.st-keyboard{
    position: absolute;
    top: 3em;
    width: 62%;
    right: 0;
    opacity: 0.5;
}
.keyboard-under-title{
    color: #ecdfe2;
    opacity: 0.6;
    padding-left: 60em;
    transform: rotate(-17deg);
    padding-top: 15em;
    margin-bottom: 0;
    position: absolute;
}