@import url(../../App.css);

.enterprice-solutions-container {
    /* background: url("../../assets/images/EnterPriceSolutions.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover; */
    padding-bottom: 20em;
    padding-left: var(--nonActiveNavWidth);
}

.enterprice-solutions-container .page-heading {
    /* margin: unset; */
    text-align: left;
    font-size: 60px;
    padding-left: 25px;
    margin-top: -16px
}

.enterprice-cards-container {
    margin-top: 4em;
    display: grid;
    /* grid-column-gap: 3%;
    -webkit-column-gap: 3%;
    column-gap: 3%;
    grid-row-gap: 1.5em; */
    grid-template-columns: repeat(auto-fill, minmax(400px, 0.5fr));
    justify-items: center;
}

.card-details-container {
    position: relative;
    padding: 3em 2em;
    color: var(--white);
    margin-top: 2em;
    border-radius: 20px;
}

.wapper{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 1px;
    background: linear-gradient(180deg, transparent, white);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.card-details-heading {
    font-family: var(--gothamBlack);
    font-size: 50px;
}

.card-details-subheading {
    font-family: var(--gothamLight);
    font-size: 26px;
}

.card-detials-card-discription {
    font-size: var(--pageSubHeading);
    font-family: var(--gothamLight);
    max-width: 400px;
}

.card-details-grid-view {
    margin-top: 4em;
    display: grid;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
    column-gap: 1em;
    grid-row-gap: 4em;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    justify-items: center;
}
.voilate{
    top: -134px;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .enterprice-solutions-container{
        padding-left: unset;
    }
    .enterprice-solutions-container .page-heading{
        font-size: 32px;
        margin: unset;
        text-align: center;
        font-weight: bolder;
    }
    .enterprice-cards-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .card-details-heading{
        font-size: 25px;
    }
    .card-details-subheading{
        font-size: 18px;
    }
    .card-detials-card-discription{
        font-size: 13px;
    }
    .card-details-grid-view{
        display: flex;
        flex-direction: column;
    }
    .product-dotimage{
        margin-top: 20em !important;
    }
}