.banner-dot0left-half-cicle-imgae {
    max-width: 535px;
    position: absolute;
    z-index: 0;
    opacity: 1.4;
    left: 10px;
    transform: rotate(2deg);
    /* transform-origin: 0% 67%; */
    top: -6px;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .banner-dot-left-half-cicle-imgae  {
        max-width: 200px !important;
        top: 0;
    }
    .mv-sw-dotimage{
        display: none;
    }
}