@import url(../../App.css);

.chat-shape-contianer {
    display: inline-block;
}

.rounded-squre-container.tab-nav-content {
    max-width: 700px;
    position: relative;
    padding: 24px 40px 0px 7em;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
}

.rounded-squre-container-white {
    max-width: 700px;
    position: relative;
    padding: 40px 40px;
    display: inline-block;
    background-color: var(--white);
}


.chat-message {
    border-radius: 40px 40px 40px 0px;
}

.chat-message::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 40px 40px 40px 0px;
    padding: 3px;
    background: linear-gradient(-3deg, transparent, transparent, white);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.chat-reply {
    border-radius: 40px 40px 0px 40px;
}

.chat-reply::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 40px 40px 0px 40px;
    padding: 3px;
    background: linear-gradient(-59deg, transparent, transparent, white);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.parallelogram.tab-nav-parall {
    width: 122px;
    height: 85px;
    transform: skew(-30deg) rotate(50deg);
    background-color: white;
    opacity: 0.2;
    margin-top: -92px;
    border-radius: 0% 0% 13% 0%;
    margin-left: -22px;
}

.parallelogram-white {
    background-color: white;
    opacity: unset;
}

.parallelogram-reply {
    position: absolute;
    right: 0;
    transform: skew(33deg) rotate(50deg);
}

.mae-triangle {
    padding-bottom: 2em;
    overflow: hidden;
}

.reply-triangle {
    padding-bottom: 3em;
    overflow: hidden;
    position: relative;
}

.chat-content {
    margin: unset;
}

.small-chat-squre-contianer {
    padding: 40px;
    opacity: 0.5;
    border: 2px solid #FFBCEC;
    backdrop-filter: blur(40px);
    border-radius: 20px 20px 20px 0px;
    min-width: 400px;
}

.small-chat-half-circle {
    width: 20px;
    height: 10px;
    border-bottom-left-radius: 110px;
    border-bottom-right-radius: 110px;
    border: 2px solid #FFBCEC;
    border-top: 0;
    backdrop-filter: blur(40px);
    margin-top: -2px;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .rounded-squre-container {
        padding: 30px 15px !important;
    }
    .parallelogram{
        width: 140px;
        height: 100px;
    }
    .chat-message{
        padding: unset;
    }
    .rounded-squre-container-white{
        padding: 20px 20px !important;
    }
    .chat-shape-contianer{
        margin-top: 15px !important;
    }
    .parallelogram.tab-nav-parall{
        width: 144px;
        height: 58px;
        transform: skew(-42deg) rotate(109deg);
        margin-top: -92px;
        border-radius: 3% 13% 0% 0%;
        margin-left: 0px;
    }
}