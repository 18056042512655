@import url(../../App.css);

.carrer-card-component {
    max-width: 907px;
    padding: 40px 30px;
    border-radius: 15px;
    margin: auto;
    display: flex;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid var(--white);
    column-gap: 10%;
}

.cc-card-left-container {
    width: 42%;
}

.cc-card-right-container {
    width: 100%;
}

.cc-bullet-point {
    display: flex;
    column-gap: 20px;
    margin-top: 1em;
}

.cc-bullet-circle{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--white);
}

.cc-overall-text{
    font-size: 30px;
    font-family: var(--GothamNarrowThin);
    color: var(--white);
}
.firstheading{
    font-size: 22px;
    font-weight: bolder;
    font-family: var(--gothamLight);
}
.secondheading{
    font-size: 20px;
    font-family: var(--gothamLight);
}

.cc-overall-text-para{
    line-height: 26px;
    font-size: 19px;
    font-family: var(--gothamLight);
}

.cc-apply-button{
    margin-top: 1em;
    color: var(--black);
    outline: none;
    border: none;
    background-color: var(--white);
    padding: 10px 60px;
    border-radius: 30px;
    font-family: var(--gothamMedium);
    font-size: 16px;
}

.modal__backdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}

.modal__container {
	border-radius: 30px;
    margin: 50px auto;
    padding: 0em 16em;
}

.button-popup {
	background: var(--blue);;
	border: 0;
	border-radius: 10px;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 30px;
	padding: 10px 15px;
	transition: transform 100ms ease-out;
}
.button-popup:hover {
    transform: scale(1.1);
}

.modal-content{
    border: unset !important;
    background-color: unset !important;
}
.cc-card-button{
    display: flex;
    flex-direction: column;
}
.career-card-head{
    font-size: 45px;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .cc-card-right-container {
        display: none;
    }
    .cc-card-left-container{
        width: unset;
        padding: 15px 15px;
    }
    .careers-card-contianer{
        padding: 30px 40px;
    }
    .carrer-card-component{
        padding: unset;
        flex-direction: column;
    }
    .cc-overall-text{
        font-size: 20px;
    }
    .cc-bullet-circle{
        margin-top: 14px;
        width: 23px;
        height: 23px;
    }
    .cc-bullet-point{
        column-gap: 6px;
    }
    .applynow-button{
        text-align: center;
        padding-bottom: 1em;
    }
    .cc-apply-button{
        padding: 5px 20px;
    }
}
