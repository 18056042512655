@import url(../../App.css);

:root {
     --quterCircleSqure: 180px;
     --innerCircleSqure: 300px;
}

.pie-chart-component-container {
     background-color: #8F2168;
     padding: 4em var(--nonActiveNavWidth);
     display: flex;
     background-image: url('../../assets/images/blue_bg_img.png');
     background-repeat: no-repeat;
     background-size: contain;
     background-position: 40%;
     display: flex;
     justify-content: center;
}

.pie-contianer {
     display: inline-grid;
     position: relative;
     background-color: #8F2168;
     border-radius: 50%;
     z-index: 1;
     margin-left: 5em;
}

.top-hemispare-contianer {
     display: flex;
}

.bottom-hemispare-contianer {
     display: flex;
     margin-top: 10px;
}

.pie-inner-circle {
     width: var(--innerCircleSqure);
     height: var(--innerCircleSqure);
     background-color: #8F2168;
     position: absolute;
     border-radius: 50%;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     border: 15px solid #8F2168;
}

.pie-text-contianer-circle {
     width: 100%;
     height: 100%;
     background: linear-gradient(319.24deg, rgba(255, 255, 255, 0.4) -4.1%, rgba(255, 255, 255, 0) 101.39%);
     border-radius: 50%;
     border: .1px solid white;
}

.pie-text-contianer {
     height: 100%;
     padding: 35px;
     text-align: center;
}

.pie-heading {
     font-size: 60px;
     font-family: var(--gothamBlack);
     color: var(--white);
}

.pie-discription {
     font-size: 28px;
     font-family: var(--gothamLight);
     color: var(--white);
     font-weight: 600;
}

.circle-part-contianer {
     cursor: pointer;
     width: var(--quterCircleSqure);
     height: var(--quterCircleSqure);
}

.active {
     border-color: #164AD0 !important;
     background: #164AD0 !important;
}

.circle-part-contianer:hover {
     background: #164AD0;
}

.quarter-circle-top-left {
     background: #EE2D67;
     margin-left: 10px;
     border-radius: 0 0 var(--quterCircleSqure) 0;
     -moz-border-radius: 0 0 var(--quterCircleSqure) 0;
     -webkit-border-radius: 0 0 var(--quterCircleSqure) 0;
     border-right: 20px solid #8F2168;
     border-bottom: 20px solid #8F2168;
}

.quarter-circle-top-right {
     background: #EE2D67;
     border-radius: 0 0 0 var(--quterCircleSqure);
     -moz-border-radius: 0 0 0 var(--quterCircleSqure);
     -webkit-border-radius: 0 0 0 var(--quterCircleSqure);
     border-left: 20px solid #8F2168;
     border-bottom: 20px solid #8F2168;
}

.quarter-circle-bottom-left {
     background: #EE2D67;
     margin-left: 10px;
     border-radius: 0 var(--quterCircleSqure) 0 0;
     -moz-border-radius: 0 var(--quterCircleSqure) 0 0;
     -webkit-border-radius: 0 var(--quterCircleSqure) 0 0;
     border-right: 20px solid #8F2168;
     border-top: 20px solid #8F2168;
}

.quarter-circle-bottom-right {
     background: #EE2D67;
     border-radius: var(--quterCircleSqure) 0 0 0;
     -moz-border-radius: var(--quterCircleSqure) 0 0 0;
     -webkit-border-radius: var(--quterCircleSqure) 0 0 0;
     border-left: 20px solid #8F2168;
     border-top: 20px solid #8F2168;
}

.pie-detials-container {
     padding: 4em 0;
     width: 40%;
}

.pie-detials-shape-contianer {
     background: linear-gradient(329.6deg, rgba(255, 255, 255, 0.4) -5.67%, rgba(255, 255, 255, 0) 105.21%);
     opacity: 0.7;
     backdrop-filter: blur(40px);
     height: 100%;
     border-radius: 0px 20px 20px 0px;
     margin-left: -70px;
     margin-top: -8em;
}
.pie-detials-heading{
     padding: 0 3em;
}
.cp-title{
     padding-left: 5em;
     font-size: 30px;
     color: var(--white);
}
.pie-detials-discription-content{
     font-size: 15px;
     color: var(--white);
     padding: 1em 10em;
     text-align: left;
     margin-bottom: 0;
     font-family: var(--gothamLight);
}
.cp-emoji{
     margin-bottom: -4em;
     margin-left: 6em;
}

/* Mobile View */
@media screen and (max-width: 992px) {
     .pie-chart-component-container{
          display: none;
     }
}