@import url(../../App.css);

.mail-shape-contianer {
    display: inline-block;
}

.mail-rounded-squre-container.tab-nav-content {
    max-width: 700px;
    position: relative;
    padding: 40px 50px 40px 40px;
    display: inline-block;
    background: linear-gradient(334.34deg, rgba(255, 255, 255, 0.56) 7.24%, rgba(255, 255, 255, 0) 104.57%);
    backdrop-filter: blur(20px);
}


.chat-message-mail {
    border-radius: 20px 20px 20px 20px;
}

.chat-message-mail::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px 20px 20px 20px;
    padding: 1.5px;
    background: linear-gradient(-3deg, transparent, transparent, white);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.chat-content {
    margin: unset;
}


/* Mobile View */
@media screen and (max-width: 992px) {
    .rounded-squre-container {
        padding: 30px 15px !important;
    }
    .chat-message{
        padding: unset;
    }
    .mail-shape-contianer{
        margin-top: 15px !important;
    }
}