@import url(../../App.css);

.graph-centre {
    position: relative;
    margin-left: 20rem;
    z-index: 999;
    width: 50px;
}

.graph-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1200px;
   
}

.graph-content {
    font-family: var( --gothamMedium);
    display: flex;
    justify-content: space-around;
    align-items: center;
    
    max-width: 1900px;
}
.face__vector {
    position: absolute;
    top: 1rem;
    left: 20px;
}
 
.contactlogo  {
    background-color: var(--white);
     border-radius: 50%;
     width: 84px;
     height: 81px;
}

.dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--white);
}
.names {
    position: relative;
}
.name-1, .name-2, .name-3 , .name-4, .name-5, .name-6 {
    position: relative;
}

.name-1-inner, .name-2-inner, .name-3-inner, .name-4-inner, .name-5-inner, .name-6-inner {

    position: relative;
}

.dot1blue, .dot2blue, .dot3blue, .dot4blue, .dot5blue, .dot6blue { 
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--blue);
}

.line {
    border-bottom: 3px solid var(--white);
}

.lineblue {
    border-bottom: 3px solid var(--blue);
}




.dot1 {
position: absolute;
top: -148px;
left:180px
}
.dot1blue{
    top: -152px;

}
.line1 {
    width: 187px;
    position: absolute; 
    left: 180px;
    margin-top: -89px;
    rotate: 31deg;
}




.line1text {
    position: absolute;
    top: -216px;
    left: 218px;
    font-size: 18px;
    color: var(--white);
    width: 250px;
    line-height: 26px;
    
}
.line1textblue {
    position: absolute;
    top: -216px;
    left: 218px;
    font-size: 22px;
    color: #164AD0;
    width: 300px;
    line-height: 30px;
    font-family: var( --gothamBold);
}

.dot2 {
    position: absolute;
    top: -196px;
    left: 588px;
}

.dot2blue{
    top: -212px;
}
.line2 {
    width: 250px;
    position: absolute; 
    left: 360px;
    margin-top: -116px;
    rotate: 148deg;
}

.dot3 {
    position: absolute;
    top: -34px;
    left: 490px
}
.dot3blue{
    /* top: -212px; */
    top: -44px;
}

.line3 {
    width: 125px;
    position: absolute; 
    left: 380px;
    top: -30px;
    rotate: 184deg;
}

.dot4 {
    position: absolute;
    top: 86px;
    left: 444px;
}
.dot4blue {
    top: 86px;
    left: 440px;
}

.line4 {
    width: 205px;
    position: absolute; 
    left: 298px;
    top: 10px;
    rotate: 238deg;
}

.dot5 {
    position: absolute;
    top:56px;
    left: 248px;
} 

.dot5blue {
    left: 230px;
}

.line5 {
    width: 165px;
    position: absolute; 
    left: 236px;
    margin-top: 0;
    rotate: 314deg;
}

.dot6 {
    position: absolute;
    top: -16px;
    left: 136px;
}

.dot6blue {
    top: -26px;
}
.line6 {
    width: 235px;
    position: absolute; 
    left: 150px;
    top: -20px;
    rotate: 354deg;
}


.line2text {
    position: absolute;
    font-size: 18px;
    color: var(--white);
    width: 250px;
    line-height: 26px;
    top: -166px;
    left: 588px;
}

.line2textblue {
    position: absolute;
    font-size: 22px;
    color: #164AD0;
    width: 250px;
    line-height: 26px;
    top: -166px;
    left: 588px;
    font-family: var( --gothamBold);

}

.line3text {
    position: absolute;
    font-size: 18px;
    color: var(--white);
    width: 250px;
    line-height: 26px;
    top: -40px;
    left: 540px
}

.line3textblue {
    position: absolute;
    font-size: 22px;
    color: #164AD0;
    width: 250px;
    line-height: 26px;
    top: -40px;
    left: 540px;
    font-family: var( --gothamBold);

}

.line4text {
    position: absolute;
    font-size: 18px;
    color: var(--white);
    width: 200px;
    line-height: 26px;
    top: 70px;
    left: 498px;
}
.line4textblue{
    position: absolute;
    font-size: 22px;
    color: #164AD0;
    width: 250px;
    line-height: 26px;
    top: 70px;
    left: 498px;
}

.line5text{
    position: absolute;
    font-size: 18px;
    color: var(--white);
    width: 200px;
    line-height: 26px;
    top:80px;
    left: 209px;
}

.line5textblue{
    position: absolute;
    font-size: 22px;
    color: #164AD0;
    width: 250px;
    line-height: 26px;
    top:100px;
    left: 209px;
}

.line6text{
    position: absolute;
    font-size: 18px;
    color: var(--white);
    width: 200px;
    line-height: 26px;
    top: 16px;
    left: 56px;
}
.line6textblue {
    position: absolute;
    font-size: 22px;
    color: #164AD0;
    width: 250px;
    line-height: 26px;
    top: 16px;
    left: 56px;
}

.nodegraph-card {
    position: relative;
    margin-top: 2rem;
}
 
.nodegraph-card-title {
    font-size: 22px;
    color: #164AD0;
    font-family: var( --gothamBold);
    width: 250px;
}

.nodegraph-card-description {
    font-size: 18px;
    color: #fff;
}

/* .graph-right {
    margin-right: 10rem;
} */

.graph-left {
    margin-right: 30rem;
}

.nodegraph-card-description {
    font-family: var( --gothamLight);
}

.nodegraph-card-link {
    font-family: var( --gothamLight);
    font-size: 16px;
    color: #fff;
display: flex;
align-items: center;
margin-top: 1rem;
}

.nodegraph-card-link-icon {
    margin-left: 0.5rem;
}

@media screen and (max-width: 992px) {
   
}