.banner-dot-bottom-right-imgae {
    right: 0;
    z-index: 0;
    display: block;
    width: 48%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 0;
    margin-top: 134em;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .banner-dot-bottom-right-imgae  {
        display: none;
    }
}