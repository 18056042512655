.banner-top-left-image-sw {
    width: 1302px;
    position: absolute;
    z-index: 0;
    opacity: 0.6;
    left: -24em;
    transform: rotate(-207deg);
    top: -19em;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .banner-top-left-image-sw  {
        display: none;
    }
}