@import url('../App.css');

.main-base-screen-container {
    background: radial-gradient(#f8b6b6 0%, #f8b6b6 4%, #f8b6b6 12.25%, #f89bb5 31.25%, #ed668c 50%) top right/200% 200%;
    animation: colorChange 50s infinite;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

@keyframes colorChange {
    0% {
        background-position: bottom right;
    }

    25% {
        background-position: bottom left;
    }

    50% {
        background-position: top left;
    }

    75% {
        background-position: top right;
    }

    100% {
        background-position: bottom right;
    }

}

/* side panel */

.sidepanel {
    width: 0;
    position: fixed;
    z-index: 9999;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--white);
    overflow-x: hidden;
    transition: 0.5s;
    margin-left: var(--navWidth);
}

.side-panel {
    background-color: var(--white);
}

.sidepanel-content-container {
    padding: 10px;
}

.sidepanel-links-container {
    margin-top: 80px;
}

.side-panel-link {
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
    padding: 15px 0;
    font-family: var(--gothamBlack);
    font-size: 25px;
    color: var(--black);
}

.explore-button {
    background: var(--labelGradint);
    outline: none;
    border-radius: 10px;
    font-family: var(--gothamMedium);
    color: var(--white);
    border: none;
    font-size: 15px;
    padding: 29px 30px 18px 30px;
    margin-top: 30px;
}

/*top panel css*/

.mobile-top-nav-bar {
    display: none;
    width: 100%;
    position: fixed;
    padding: 30px 50px;
    justify-content: space-between;
    z-index: 99;
}

.mobile-ham-icon {
    height: 30px;
    width: 25px;
    color: var(--white);
    margin: auto 0;
}

.mobile-header-icon {
    width: 33px;
    height: 45px;
}

.cross-icon {
    width: 33px;
    height: 30px;
}

.top-panel {
    width: 100%;
    position: fixed;
    z-index: 99;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    background-color: var(--white);
    overflow-x: hidden;
    transition: 0.5s;
    border-radius: 0px 0px 30px 30px;
}

.m-top-panel-contianer {
    padding: 30px 42px;
    height: 100%;
    position: relative;
}

.m-t-p-header-contianer {
    display: flex;
    justify-content: space-between;
}

.m-t-p-icon {
    width: 74px;
    height: 103px;
}

.m-t-p-body-flex-container {
    height: 100%;
    padding-bottom: 30px;
}

.m-t-p-body-flex {
    display: flex;
    flex-direction: column;
    row-gap: 17px;
    height: 100%;
    justify-content: center;
}

/* .m-t-p-links-contianer {
    
} */

.m-t-p-link {
    text-decoration: none;
    display: block;
    width: 100%;
    font-family: var(--gothamBlack);
    font-size: 35px;
    color: var(--black);
}

.m-t-p-g-text {
    width: 136px;
    margin-top: 10px;
}

.m-t-p-g-text-p {
    margin: unset;
    font-family: var(--gothamMedium);
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    font-weight: 400;
}

.m-t-p-bottom-iland {
    height: 6.5px;
    width: 58px;
    background-color: var(--black);
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
}

/* side navigation */

.sidenav-closed {
    height: 100%;
    width: var(--nonActiveNavWidth);
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: transparent;
    overflow-x: hidden;
    z-index: 99;
}

.sidenav-open {
    height: 100%;
    width: var(--navWidth);
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--white);
    overflow-x: hidden;
    z-index: 9999;
}

.ham-container {
    padding: 50px 50px 0px 70px;
}

.ham-icon {
    color: var(--white);
    font-size: 40px;
    cursor: pointer;
    transition: 0.5s;
}

.ham-icon-black {
    color: var(--black);
}

/* .base-screen-container {
    margin-left: var(--nonActiveNavWidth);
} */

.sidebar-logo-container {
    width: 100%;
    height: 55%;
    display: flex;
    justify-content: center;
}

.sidebar-logo {
    width: 80px;
    object-fit: contain
}

.sidebar-logo-black {
    filter: brightness(0%);
}

.sidebar-labels-container {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
}

/* top sound icon container */

.base-page-top-sound-contianer {
    position: absolute;
    right: 0;
    margin-right: 4em;
    margin-top: 2em;
    cursor: pointer;
    z-index: 999;
}

.base-page-top-sound-contianer-img {
    width: 30px;
}

.base-page-top-sound-contianer-text {
    color: var(--white);
    font-size: 10px;
    font-family: var(--gothamMedium);
    margin-right: 10px;
}

.label-center {
    width: 100%;
    text-align: center;
    display: none;
}

.sidebar-labels-container .sidebar-label {
    display: inline-block;
    width: 129px;
}

.sidebar-labels-container .sidebar-label .label {
    font-family: var(--gothamBold);
    text-align: left;
    font-size: 17px;
    margin: 3px;
}

.label-gradient {
    background: var(--labelGradint);
    height: 8px;
    width: 100%;
    display: block;
}

.label-subtext-type {
    font-size: 14px;
    font-family: var(--gothamBookItalic);
    text-align: left;
    margin: 4px;
}

.sidebar-labels-dot {
    height: 20px;
    width: 20px;
    /* background-color: var(--white); */
    background-color: var(--white);
    border-radius: 50%;
    margin: 18px auto;
}

/* side nav hover animation effect */
.sidenav-closed:hover {
    background-color: var(--white);
    width: var(--navWidth);
    z-index: 9999999;
}

.sidenav-closed:hover .sidebar-labels-dot {
    display: none;
}

.sidenav-closed:hover .label-center {
    display: inline-block;
}

.sidenav-closed:hover .ham-icon {
    color: var(--black);
}

.sidenav-closed:hover .sidebar-logo {
    filter: brightness(0%);
}

.label-center-des{
    padding-top: 6em;
}

/*media query for mobile screen*/

@media screen and (max-width: 992px) {
    .sidepanel {
        display: none;
    }

    .sidenav-closed {
        display: none;
    }

    .base-page-top-sound-contianer {
        display: none;
    }

    .mobile-top-nav-bar {
        display: flex;
    }

    .base-page-top-sound-contianer-m {
        z-index: 999;
    }

    .base-page-top-sound-contianer-img {
        filter: brightness(0%);
    }

    .base-page-top-sound-contianer-text {
        color: var(--black);
    }

    .explore-button {
        width: 200px;
        margin-top: unset;
        padding: 18px 30px 8px 30px;
    }
}

@media screen and (max-width: 992px) {
    .mobile-top-nav-bar {
        display: flex;
        width: unset;
        position: unset;
        padding: 30px 40px;
    }
}