@import url(../../App.css);

.theme-button {
    padding: 5px 20px;
    outline: none;
    border: 2px solid var(--white);
    border-radius: 8px;
    color: var(--white);
    font-family: var(--gothamLight);
    font-weight: bolder;
    background: transparent;
}

.theme-button:hover {
    background: var(--theme-background-element) !important;
    border: unset !important;
    padding: 7px 20px;
}