@import url('../../App.css');

/* .home-page-container {
    height: 100vh;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets/images/Homepage.png');
    padding-left: var(--nonActiveNavWidth);
} */

.heading-container {
    padding-top: 14em;
}

.heading {
    font-size: 70px;
    font-family: var(--gothamThin);
    text-align: center;
    margin: 23px;
    font-weight: 400;
    color: #0B0C1F;
}

.option-component-validation {
    margin-top: 21vh;
}
.home-bottom-circle{
    display: none
}


@media screen and (max-width: 992px) {
    .heading {
        font-size: 30px;
        margin: unset;
        font-weight: bolder;
    }
    .home-page-container{
        padding: 30px 40px;
    }
    .heading-container{
        padding-top: 5em;
    }
}