.lottie {
    margin-top: -13em;
    margin-right: -4em;
    margin-left: 5em;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .lottie {
        margin-top: -7em;
        margin-right: -3em;
    }
}