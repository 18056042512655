@import url(../../App.css);

.ss-banner-continaer {
    padding-left: var(--nonActiveNavWidth);
    padding-top: 2em;
    padding-bottom: 6em;
    max-width: 1200px;
    margin: auto;
}

.ss-banner-card-contianer {
    display: flex;
    column-gap: 30px;
}

.ss-search-icon {
    width: 35px;
    height: 35px;
    margin-top: 20px;
}

.ss-cursor {
    animation: blink 1s linear infinite forwards;
}

.animated-text {
    color: #F3F4C2;
}

.ss-sec-2-search-continaer {
    width: 60%;
    margin: auto;
}

.ss-sec-2-continaer {
    /* margin-left: var(--nonActiveNavWidth); */
    padding: 4em 0;
}

.ss-search-bar-contianer {
    display: flex;
    column-gap: 30px;
}

.ss-search-icon-2 {
    width: 35px;
    height: 35px;
}

.ss-sec-2-search-result-contianer {
    width: 100%;
    padding: 35px 30px;
    padding-top: 6em;
    background: linear-gradient(319.24deg, rgba(255, 255, 255, 0.4) -4.1%, rgba(255, 255, 255, 0) 101.39%);
    backdrop-filter: blur(40px);
    border-radius: 0px 0px 64px 64px;
    margin-top: -47px;
    z-index: 0;
}

.ss-sec-2-result-contianer {
    display: flex;
}

.ss-sec-2-result-left-contianer {
    width: 70%;
}

.ss-sec-2-result-right-contianer {
    width: 30%;
    padding: 0 0 0 40px;
}

.ss-result-icon-with-heaing{
    display: flex;
    column-gap: 30px;
}

.ss-result-icon-with-heaing-bottom{
    padding-bottom: 20px;
}

.ss-result-icon-with-heaing-top{
    padding-top: 20px;
}

.top-border{
    border-top: 2px solid var(--white);
}

.ss-search-2-result-heading-contianer{
    border-top: 2px solid var(--white);
    padding-top: 20px;
}

.search-icon-result-1-icon{
    margin-top: 30px;
}

.ss-sec-2-search-result-dis{
    font-size: 12px;
    color: var(--white);
    font-family: var(--gothamLight);
}

.ss-sec-4-contianer{
    margin-left: var(--nonActiveNavWidth);
    padding: 4em;
    display: flex;
    column-gap: 3em;
}

.ss-sec-4-left-continaer{
    width: 20%;
}

.ss-sec-4-right-continaer{
    width: 80%;
}

.ss-sec-4-left-dis{
    margin-top: 20px;
}

.crome-image-ss-sec-4{
    width: 100%;
}
.ssc-head{
    padding-top: 4em;
    padding-left: 80px;
    font-family: var(--gothamLight);
}
.sc-exp-button{
    padding-left: 80px;
}
.ss-scc-2-heading{
    line-height: 58px;
    text-align: center;
    font-size: 58px;
}
.ssc-chaticon{
    position: absolute;
    padding-left: 24em;
    margin-top: -4em;
}
.mv-ss-scc-2-heading{
    display: none;
}
.ssc-form{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    padding-top: 2em;
}
.mv-sf-scc-2-heading{
    display: none;
}
.mv-para{
    display: none;
}
.ss-cricle{
    position: absolute;
    left: -25em;
    opacity: 0.4;
    margin-top: -60px;
}
.ss-cricle-sec{
    position: absolute;
    margin-top: 10em;
    opacity: 0.4;
    width: 400px;
    transform: rotate(37deg);
    right: 172px;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .ss-banner-continaer {
        padding: 30px 40px;
    }
    .shadow-box-border-wraper{
        width: 240px !important;
        border-radius: 18px !important;
    }
    .ss-sec-2-continaer{
        padding: 30px 40px;
    }
    .ss-sec-2-result-contianer{
        flex-direction: column;
    }
    .ss-sec-2-result-right-contianer{
        width: unset;
    }
    .ss-sec-4-contianer{
        margin-left: unset;
        flex-direction: column;
    }
    .ss-sec-4-left-continaer{
        width: unset;
    }
    .what-makes-points-contianer{
        flex-direction: column;
    }
    .what-makes-left-points-contianer{
        width: unset;
    }
    .what-makes-right-points-contianer{
        border-top: 2px solid var(--white);
    }
    .ssc-head{
        padding-top: 2em;
    }
    .ssc-head{
        font-size: 18px;
        letter-spacing: 5px;
    }
    .ss-search-icon{
        width: 20px;
        height: 20px;
    }
    .sf-scc-2-heading{
        display: none;
    }
    .mv-sf-scc-2-heading{
        display: unset;
    }
    .sf-sec-2-heading-contianer{
        text-align: center;
    }
    .ssc-chaticon{
        display: none;
    }
    .ssc-form{
        width: 100%;
        padding: 30px 20px;
    }
    .ss-sec-2-search-continaer {
        width: unset;
    }
    .ss-search-icon-2{
        width: 20px;
        height: 20px;
    }
    .sc-pagehead{
        font-size: 15px;
    }
    .sc-para{
        display: none;
    }
    .mv-para{
        display: unset;
    }
    .mv-para-desc{
        width: unset;
        padding: 30px 40px;
        font-size: 12px;
    }
    .ss-sec-2-search-result-contianer{
        padding: 35px 20px;
        padding-top: 6em;
        border-radius: 0 0 12px 12px;
    }
    .ss-sec-2-result-left-contianer{
        width: 100%;
    }
    .mv-card{
        border: unset !important;
    }
    .mv-ss-scc-2-heading{
        display: unset;
    }
}