@import url(../../App.css);

.social-component-container{
    /* background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets/images/socialBackground.png'); */
    padding-left: var(--nonActiveNavWidth);
}

.section-subheading {
    text-align: center;
    color: var(--blue);
}

.sec3-subtext-container {
    margin-top: 2em;
    width: 56vh;
    font-family: var(--gothamLight);
    font-weight: 600;
}
.sec3-desc-container{
    width: 52vh;
}

.computer-image-container2 {
    width: 100%;
}

.sec-heading-container2 {
    width: 40%;
    margin: auto 7em;
}

.social-sec-4-container .top-p {
    font-family: var(--gothamLight);
    font-size: 28px;
    width: 100%;
    max-width: 500px;
    color: var(--white);
}

.social-sec-4-container .bottom-p {
    font-family: var(--gothamLight);
    font-size: 28px;
    color: var(--white);
    text-align: right;
}

.social-sec-4-container .bottom-p-container{
    width: 100%;
    text-align: right;
    margin-top: 2em;
}

.social-sec-4-container .bottom-p-container-text{
    width: 85vh;
    display: inline-block;
    padding-right: 14em;
}

.social-sec-4-container  .middle-heading{
    text-align: left;
    width: 77%;
}

.small-assets-heading{
    width: 36px;
}

.heading-underline{
    text-decoration: underline;
}

.weather-heading-text-decoration{
    text-decoration: underline;
}
.middle-heading{
    font-size: 64px;
}
.socialstudy{
    width: 75%;
}
.mv-sw-heading{
    display: none;
}
.mv-para{
    display: none;
}
.mv-top-para{
    display: none;
}
.sw-head{
    line-height: 58px;
}
.sw-para{
    font-size: 14px;
    color: var(--white);
    font-family: var(--gothamLight);
    font-weight: 600;
}
.socialstudy {
    width: 75%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.animation-head{
    font-size: 80px;
    color: var(--white);
    font-family: var(--gothamBlack);
    text-align: center;
    text-transform: uppercase;
    padding-top: 2em;
    line-height: 68px;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .social-component-container{
        padding-left: unset;
    }
    .sec-heading-container2 {
        width: 60%;
        margin: auto 3em;
    }
    .mv-sw-heading{
        display: unset;
    }
    .sec3-subtext-container{
        width: unset;
    }
    .sec3-desc-container{
        width: unset;
    }
    .mv-middle-head{
        font-size: 30px;
        padding-top: 1em;
    }
    .social-sec-4-container .bottom-p-container-text{
        width: unset;
        padding-right: unset;
    }
    .mv-para{
        display: unset;
    }
    .mv-sw-para{
        display: none;
    }
    .mvpara{
        padding-top: 2em;
        width: 200px;
        margin-bottom: 0;
    }
    .mvpara2{
        width: 225px;
    }
    .top-p{
        display: none;
    }
    .mv-top-para{
        display: unset;
        font-size: 20px !important;
    }
    .social-sec-4-container .bottom-p{
        font-size: 19px;
    }
    .socialstudy {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .social-component-container{
        padding-bottom: unset;
    }
}