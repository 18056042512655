@import url(../../App.css);

.section-view-container{
    padding: 3em 7em;
    position: relative;
}
/* Mobile View */
@media screen and (max-width: 992px) {
    .section-view-container{
        padding: 30px 40px;
    }
}