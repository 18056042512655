@import url('../../App.css');

.social-component-container{
    padding-bottom: 6vh;
}

.options-container {
    display: flex;
    justify-content: center;
    padding: 0 6em;
}

.option-container {
    display: flex;
    width: 236px;
    text-decoration: none;
    color: var(--black);
}

.option-circle-image {
    width: 25px;
    height: 25px;
    margin: 20px 0;
}

.option-content-contanier {
    margin-left: 10px;
    width: 220px;
}

.option-heading {
    margin: 0;
    font-size: 25px;
    font-family: var(--gothamBold);
    color: #0B0C1F;
}

.option-subtext {
    margin: 0;
    font-size: 16px;
    font-family: var(--gothamBookItalic);
    margin-top: -6px;
    color: #0B0C1F;
}
.options-container-description{
    display: contents;
}

/* Mobile view */
@media screen and (max-width: 992px) {
    .options-container {
        display: flex;
        flex-direction: column;
        padding: unset;
    }
    .option-container {
        width: 280px;
    }
    .option-heading {
        font-size: 22px;
    } 
    .option-subtext {
        font-size: 13px;
    }
    .options-container-description{
        display: flex;
        padding-bottom: 2em;
    }
}