.banner-top-left-half-cicle-imgae {
    /* max-width: 500px; */
    position: absolute;
    z-index: 0;
    opacity: 0.4;
    left: 0;
    transform: rotate(-86deg);
    transform-origin: 40% 60%;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .banner-top-left-half-cicle-imgae  {
        display: none;
    }
}