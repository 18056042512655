@import url(../../App.css);

.com-heading{
    text-align: center;
}

.center-heading{
    margin-top: 1.5em;
}

.sec-option-container{
    margin-top: 8em;
}
.howwe{
    justify-content: space-evenly;
}
.opt-howwe{
    display: flex;
}
