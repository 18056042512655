@import url(../../App.css);

.sc-blue-text {
    color: var(--blue);
}

.sc-yellow-text {
    color: var(--yellowThemeColor);
}

.sc-page-contianer {
    padding-top: 2em;
}

.sc-banner-contianer {
    padding-left: 15em;
    padding-bottom: 6em;
}

.head-banner-chat-records-container {
    max-width: 800px;
    margin: 0 0 0 5em;
    position: relative;
}

.banner-chat-reply-container {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 8em;
}

.sc-sec-2-continaer {
    text-align: center;
}

.sc-sec-2-para-div-contianer {
    padding-top: 3em;
    padding-bottom: 16em;
    max-width: 500px;
    margin: auto;
}

.sc-sec-3-contianer {
    padding-left: var(--nonActiveNavWidth);
    display: flex;
    padding-bottom: 3em;
    padding-right: 4em;
    column-gap: 20px;
}

.sc-sec-3-image-contianer {
    width: 50%;
}

.sc-sec-3-image {
    width: 100%;
}
.sc-sec-3-content-contianer {
    width: 50%;
}

.sc-sec-3-paras-contianer {
    /* max-width: 400px; */
    margin-left: 3em;
    margin-top: -2em;
}

.sc-sec-4-contianer {
    display: flex;
    overflow: hidden;
    padding-bottom: 4em;
}

.sc-sec-4-continer-left {
    width: 60%;
    padding-top: 2em;
    padding-bottom: 2em;
}

.sc-sec-4-continer-right {
    width: 50%;
    position: relative;
}

.sc-sec-4-heading-container {
    background-color: var(--lightBlue);
    padding-left: var(--nonActiveNavWidth);
    padding-top: 6em;
    padding-bottom: 6em;
    background-image: url('../../assets/images/blue_bg_img.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 80%;
}

.sc-sec-4-heading-padding {
    padding-left: 4em;
    text-align: center;
}

.sc-sec-4-image {
    position: absolute;
    height: 500px;
    width: 125%;
    left: -12%;
}

.sc-sec-4-chat-contianer {
    position: absolute;
    z-index: 1000;
    padding: 5px;
    padding: 30px 12px 10px 19px;
    width: 100%;
    height: 100%;
    display: flex;
    font-weight: bolder;
}

.sc-sec-4-chat-arrow-contianer {
    padding: 0 30px;
    color: var(--white);
    position: relative;
}

.arrow-size-up-down {
    font-size: 30px;
}

.sc-sec-4-up-down-arrow {
    font-size: 30px;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-bottom: 20px;
    left: 12px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.sc-sec-4-chats-contianer {
    overflow-y: scroll;
    padding-right: 30px;
}

.sc-sec-4-chat-text {
    font-size: 25px;
    font-family: var(--gothamMedium);
    color: var(--darkPink);
    font-weight: 400;
    line-height: 30px;
}

.chat-time-para {
    font-size: 18px;
    font-family: var(--gothamLight);
    color: var(--darkPink);
    font-weight: 600;
}

.sc-sec-4-chat-reply-text {
    font-size: 25px;
    font-family: var(--gothamLight);
    color: var(--white);
    font-weight: 600;
    line-height: 30px;
}

.chat-reply-time-para {
    font-size: 18px;
    font-family: var(--gothamLight);
    color: var(--white);
    font-weight: 600;
}

.sc-chat-icon {
    width: 50px;
}

.sc-s-chat-continaer {
    margin-left: var(--nonActiveNavWidth);
    padding: 4em 0 4em 7em;
}

.sc-s-chat-parap-contianer {
    margin-top: 2em;
    font-family: var(--gothamLight);
    font-size: 16px;
}
.sc-cht-shape-head{
    font-size: 70px;
}

.sc-s-chat-para-chat-heading-shape-contianer {
    display: flex;
    column-gap: 100px;
}

.chat-heading-shape-contianer {
    display: flex;
    column-gap: 20px;
}

.chat-shape-lady-image {
    width: 100px;
    height: 100px;
    margin: auto;
}

.chat-heading-shape-para {
    font-size: 20px;
    color: var(--white);
    font-family: var(--gothamLight);
    line-height: 22px;
}

.chat-heading-click-text {
    font-family: var(--gothamMedium);
}

.chat-fun-contianer {
    /* width: 84%; */
    margin-top: 4em;
}

.chat-fun-contianer-div{
    width: 100%;
    margin: auto;
}
.chat-heading{
    padding: 2em 0 0 80px;
    font-weight: bolder;
    letter-spacing: 12px;
}

.chat-sub{
    padding-left: 80px;
    font-size: 18px;
    letter-spacing: 4px;
    font-family: var(--gothamLight);
}
.ss-chaticon{
    position: absolute;
    padding-left: 8em;
}
.sc-head{
    text-transform: uppercase;
    font-size: 40px;
}
.mv-chtmessage{
    display: none;
}
.chat{
    margin-bottom: 0;
    font-size: 18px;
    font-family: var(--gothamMedium);
    padding-left: 80px;
    font-weight: normal;
    letter-spacing: 1px;
    font-weight: bolder;
}

.sc-paragraph{
    font-family: var(--gothamLight);
    font-weight: 400;
    font-size: 20px;
}
.arrow{
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5em;
}
.sc-subhead{
    line-height: 58px;
    font-size: 60px;
}
.ss-{
    width: 5%;
}
.sc-cricle{
    position: absolute;
    right: 0;
    margin-top: -10em;
    opacity: 0.6;
}
.DialogueMessage2{
    position: absolute;
    margin-left: 3em;
    margin-top: 8em;
    width: 412px;
}
.DialogueMessage{
    margin-left: -17em;
    width: 420px;
}
.DialogueMessage3{
    margin: 183px 0 0 -23em;
    position: absolute;
    width: 250px;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .sc-banner-contianer {
        padding: 30px 40px;
    }
    .sc-page-contianer{
        padding-top: unset;
    }
    .sc-sec-2-continaer{
        padding: 30px 40px;
    }
    .pageHeadingMed{
        font-size: 25px;
    }
    .sc-sec-3-contianer{
        padding: 30px 40px;
        flex-direction: column;
    }
    .sc-sec-3-image-contianer{
        width: unset;
        padding-bottom: 2em;
    }
    .sc-s-chat-continaer{
        margin-left: unset;
        padding: 3em 0 0 0;
    }
    .chat-fun-contianer{
        width: 100%;
    }
    .chat-heading-shape-contianer {
        flex-direction: column;
        margin-top: 30px;
    }
    .sc-s-chat-para-chat-heading-shape-contianer{
        flex-direction: column;
    }
    .pageSubHeading{
        font-size: 18px;
    }
    .sc-sec-3-paras-contianer{
        margin-left: 0;
        margin-top: 2em;
    }
    .sc-sec-4-continer-left{
        width: unset;
    }
    .sc-sec-4-contianer{
        flex-direction: column;
    }
    .sc-sec-4-heading-container{
        padding-left: unset;
        padding-top: 2em;
        padding-bottom: 16em;
    }
    .sc-sec-4-image{
        height: unset;
        left: 0;
        position: unset;
        width: 100%;
    }
    .sc-sec-4-continer-right{
        width: unset;
        margin-top: -16em;
    }
    .sc-sec-4-up-down-arrow{
        align-items: center;
        right: -15px;
        row-gap: 10px;
        padding-bottom: 6px;
    }
    .sc-sec-4-chat-contianer {
        padding: 30px 12px 25px 19px;
    }
    .arrow-size-up-down-sc{
        font-size: 15px;
    }
    .sc-sec-4-chat-text {
        font-size: 18px;
    }
    .sc-sec-4-heading-padding {
        padding-left: unset;
    }
    .chat{
        margin: unset;
        font-size: 24px;
        letter-spacing: 2px;
    }
    .chat-sub{
        margin: unset;
        font-size: 18px;
        letter-spacing: 2px;
    }
    .chat-heading{
        /* font-size: 16px;
        letter-spacing: 5px;
        margin-top: 0.5em;
        padding: unset; */
        display: none;
    }
    .ss-chaticon{
        display: none;
    }
    .head-banner-chat-records-container{
        margin: unset;
    }
    .mv-chtmessage{
        display: unset;
        font-size: 16px;
    }
    .sc-chtmessage{
        display: none;
    }
    .ss-{
        width: 20px;
    }
}