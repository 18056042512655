.product {
    transform: rotate(180deg);
    right: 0;
    bottom: 280px;
    transform-origin: bottom right;
    transform: rotate(-90deg);
    display: block;
    padding: 0;
    position: fixed!important;
    z-index: 99999;
    font-size: 24px;
    color: #FFFFFF;
}
.product-text{
    font-size: 20px;
    border-bottom: 3px solid;
    width: 64px;
    font-weight: 500;
    margin-bottom: 1em;
}
a:hover {
    color: unset !important;
    text-decoration: unset !important; 
}
a {
    color: unset !important;
    text-decoration: unset !important; 
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .product  {
        display: none;
    }
}