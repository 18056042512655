.banner-top-imgae-sacc {
    max-width: 500px;
    position: absolute;
    right: 0;
    z-index: 0;
    opacity: 0.4;
    display: none;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .banner-top-imgae-sacc {
        max-width: 200px !important;
        top: 0;
        display: unset;
    }
}