@import url(../../App.css);

.contact-us-page-contianer {
    /* padding-bottom: 6em; */
}

.contact-data-container{
    padding: 0 0 3em 8em;
}

.contact-map-address-details-contianer {
    text-align: center;
}

.contact-map-image {
    max-width: 60vw;
    position: relative;
    z-index: 1;
}

.address-details-container {
    max-width: 70vw;
    margin: auto;
    margin-top: -6em;
}

.address-detials-container-card {
    padding: 5em 2em 2em 2em;
    background-image: linear-gradient(28.83deg, rgba(153, 110, 243, 0.4) 18.39%, rgba(216, 197, 255, 0) 98.87%);
    width: 100%;
    text-align: left;
    color: var(--white);
}

.constact-card-address-container {
    display: flex;
}

.constact-card-address-container-right {
    width: 60%;
}

.constact-card-address-container-left {
    width: 40%;
}

.const-card-left-heading {
    font-size: 18px;
    font-family: var(--gothamMedium);
    margin: unset;
}

.const-card-left-heading-bottom-border {
    width: 80px;
    background-color: var(--white);
    height: 4px;
    display: block;
}

.const-card-left-subheading {
    margin-top: 40px;
    font-family: var(--gothamLight);
}

.address-element-container{
    display: flex;
}

.address-element-container-margin-top{
    margin-top: 20px;
}

.address-element-icon{
    font-size: 30px;
}

.address-element-content{
    margin-left: 10px;
    font-family: var(--gothamLight);
    font-size: 18px;
}
.contct-arrow{
    margin-right: 5vh;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .contact-map-image {
        max-width: 80vw;
    }
    .address-details-container{
        max-width: 90vw;
        margin-top: -4em; 
    }
    .constact-card-address-container{
        display: unset;
    }
    .address-detials-container-card{
        padding: 3em 1em 2em 1em;
    }
    .constact-card-address-container-left{
        width: unset;
    }
    .constact-card-address-container-right{
        width: unset;
    }
    .address-element-icon-building{
        font-size: 80px;
    }
    .mv-contactcard{
        width: 280px !important;
    }
    .address-element-container{
        display: inline-flex;
    }
}