@import url(../../App.css);


.d-b-banner-contianer {
    padding-top: 7em;
    padding-left: var(--nonActiveNavWidth);
    position: relative;
}

.d-b-banner-width-center {
    display: flex;
    max-width: 1000px;
    margin: auto;
}

.d-b-banner-image {
    width: 100%;
    max-width: 500px;
}

.d-b-banner-heading-contaner {
    padding-top: 2em;
    margin-left: -3em;
}

.d-b-banner-icon {
    width: 80px;
}

.d-b-banner-icon-contianer {
    margin-top: 5em;
    margin-left: -7em;
}

.d-b-sec-2-contianer {
    margin-left: var(--nonActiveNavWidth);
    padding-left: 4em;
    padding-top: 4em;
    padding-bottom: 4em;
    display: flex;
    justify-content: space-between;
}

.d-b-sec-2-heading-continaer{
    max-width: 500px;
    margin: auto;
}

.d-b-sec-2-image-container{
    /* position: relative; */
    text-align: right;
}

.d-b-sec-2-image{
    max-width: 600px;
    /* position: absolute; */
    right: 0;
}
.backicon-dobar{
    margin-left: 10em;
}
.dobar-arrow{
    display: block;
}