.banner-top-half-cicle-imgae{
    max-width: 640px;
    position: absolute;
    right: 0;
    z-index: 0;
    opacity: 0.4;
    top: 0;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .banner-top-half-cicle-imgae {
        max-width: 200px !important;
        top: 0;
    }
}