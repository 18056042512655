@import url(../../App.css);

.chat-shape-contianer {
    display: inline-block;
}

.rounded-squre-container {
    max-width: 600px;
    position: relative;
    padding: 40px 40px;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
}

.rounded-squre-container-white {
    max-width: 700px;
    position: relative;
    padding: 40px 40px;
    display: inline-block;
    background-color: var(--white);
}


.chat-message {
    border-radius: 20px 20px 20px 0px;
}

.chat-message::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px 20px 20px 0px;
    padding: 1px;
    background: linear-gradient(-3deg, transparent, transparent, white);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.sc-chat-message {
    border-radius: 40px 40px 40px 0px;
}

.sc-chat-message::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 40px 40px 40px 0px;
    padding: 3px;
    background: linear-gradient(-3deg, transparent, transparent, white);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.sc-white-chat-message {
    border-radius: 40px 40px 40px 0px;
}

.sc-white-chat-message::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 40px 40px 40px 0px;
    padding: 3px;
    background: linear-gradient(-3deg, transparent, transparent, white);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.chat-reply {
    border-radius: 40px 40px 0px 40px;
}

.chat-reply::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 40px 40px 0px 40px;
    padding: 3px;
    background: linear-gradient(-59deg, transparent, transparent, white);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.parallelogram {
    width: 91px;
    height: 111px;
    transform: skew(-15deg) rotate(50deg);
    background-color: white;
    opacity: 0.2;
    margin-top: -92px;
    border-radius: 0% 0% 18% 0%;
    margin-left: -5px;
}

.parallelogram-white-sc {
    opacity: unset;
    width: 122px;
    height: 85px;
    transform: skew(-30deg) rotate(50deg);
    background-color: white;
    margin-top: -92px;
    border-radius: 0% 0% 13% 0%;
    margin-left: -22px;

}

.parallelogram-reply {
    position: absolute;
    right: 0;
    transform: skew(36deg) rotate(50deg);
}

.mae-triangle {
    padding-bottom: 2em;
    overflow: hidden;
}

.reply-triangle {
    padding-bottom: 3em;
    overflow: hidden;
    position: relative;
}

.chat-content {
    margin: unset;
}

.small-chat-squre-contianer {
    padding: 40px;
    opacity: 0.5;
    border: 2px solid #FFBCEC;
    backdrop-filter: blur(40px);
    border-radius: 20px 20px 20px 0px;
    min-width: 400px;
}

.small-chat-half-circle {
    width: 20px;
    height: 10px;
    border-bottom-left-radius: 110px;
    border-bottom-right-radius: 110px;
    border: 2px solid #FFBCEC;
    border-top: 0;
    backdrop-filter: blur(40px);
    margin-top: -2px;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .rounded-squre-container {
        padding: 30px 30px !important;
    }
    .parallelogram{
        width: 140px;
        height: 100px;
    }
    .chat-message{
        padding: unset;
    }
    .rounded-squre-container-white{
        padding: 20px 20px !important;
    }
    .chat-shape-contianer{
        margin-top: 15px !important;
    }
    .chat-message {
        border-radius: 30px 30px 30px 0px;
    }
    .chat-message::before{
        border-radius: 30px 30px 30px 0px;
    } 
    .chat-reply{
        border-radius: 30px 30px 0px 30px
    }
    .chat-reply::before{
        border-radius: 30px 30px 0px 30px
    }
}