.banner-dot-bottom-whywe-image {
    max-width:230px;
    position: absolute;
    z-index: 0;
    right: 0;
    transform: rotate(0deg);
    opacity: 0.4;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .banner-dot-bottom-whywe-image  {
        display: none;
    }
}