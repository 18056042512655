@import url('../../App.css');

.enterprice-card-container {
    position: relative;
    padding: 30px 30px;
    margin: 5px;
    display: inline-block;
    /* background: linear-gradient(335.09deg, #3a65d0 4.6%, rgba(255, 255, 255, 0) 100.02%); */
    opacity: 0.7;
    border-radius: 20px;
    max-width: 500px;
    width: 100%;
    height: 70%;
}

.enterprice-card-container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 1px;
    background: linear-gradient(155deg, transparent, white);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.heading-enterprice-card {
    font-size: 44px;
    font-family: var(--gothamBlack) !important;
    color: var(--white);
}

.enterprice-card-subheading {
    font-size: 18px;
    font-family: var(--gothamLight);
    color: var(--white);
    padding: unset;
    width: 15em;
}

.entrprice-card-subtext {
    font-size: 14px;
    font-family: var(--gothamLight);
    color: var(--white);
    padding: unset;
    width: 15em;
}

.enterprice-card-3-bars-image{
    height: 100%;
}

.enterprice-main-bg{
    height: 250px;
    object-fit: scale-down;
    width: 100%;
}

.enterprice-card-images-container{
    margin-top: 40px;
}
/* Mobile View */
@media screen and (max-width: 992px) {
    .heading-enterprice-card{
        font-size: 25px;
    }
    .enterprice-card-subheading{
        font-size: 16px;
        width: 12em;
    }
    .entrprice-card-subtext{
        font-size: 12px;
    }
    .enterprice-card-images-container{
        margin-top: 0;
    }
}