.banner-dot-bottom-howwe-image {
    /* position: absolute; */
    right: 0;
    z-index: 0;
    display: block;
    transform: rotate(0deg);
    margin-left: auto;
    margin-top: -758px;
}

/* Mobile View */
/* @media screen and (max-width: 992px) {
    .banner-dot-left-half-cicle-imgae  {
        max-width: 200px !important;
        top: 0;
    }
} */