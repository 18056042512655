@import url(../../App.css);

/* styles for keyboard */

.keyboard-component-comtainer{
    /* padding-top: 6em; */
    width: 57em;
}

.keyboard {
    text-align: center;
    font-size: 16px;
    font-family: sans-serif;
}

.keyboard__row {
    display: inline-block;
    height: 3em;
    margin: 0.2em;
}

.keyboard__row--h1 {
    height: 1.7em;
    line-height: 1.4em;
}

.keyboard__row--h3 {
    height: 3.3em;
}

.keyboard__row > * {
    position: relative;
    background: #ecdfe2;
    text-align: center;
    color: #a89da4;
    float: left;
    border-radius: 0.3em;
    margin: 0.2em;
    padding: 0.2em;
    width: 3.3em;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-user-select: none;
    box-shadow: 0 0.2em 0 0.1em #9b8589;
}

.keyboard__row--h1 > div {
    width: 3.5em;
}

.keyboard__row > div[data-pressed],
.keyboard__row > div:active {
    background: #ecdfe2;
    color: #a89da4;
    position: relative;
    top: 0.2em;
    box-shadow: 0 0 0 0.05em black;
}

.key--w3 {
    width: 4.6em;
}

.key--w4 {
    width: 6em;
}

.key--w5 {
    width: 6.5em;
}

.key--w6 {
    width: 8.3em;
}

.key--space {
    width: 18em;
}

.key--double > * {
    padding-top: 0.1em;
}

.key--letter {
    line-height: 2.8em;
}

.key--bottom-left > * {
    position: absolute;
    text-align: left;
    bottom: 0.4em;
    left: 0.4em;
}

.key--bottom-right > * {
    position: absolute;
    text-align: right;
    bottom: 0.4em;
    right: 0.4em;
}

.key--fn > * {
    font-size: 0.6em;
    line-height: 1em;
    padding-top: 1.2em;
    padding-right: 0.2em;
    text-align: right;
    float: right;
}

.key--word > * {
    font-size: 0.8em;
}

.key--arrow--tall > *,
.key--arrow > * {
    font-size: 0.5em;
    line-height: 3em;
}

.key--arrow {
    height: 1.8em;
    margin-top: 1.7em;
    line-height: 0.5em;
}

.key--arrow--tall > * {
    padding-top: 0.2em;
}

.keyboard > .keyboard__row {
    text-align: center;
}

.pressed-key{
    background: #a79ba1;
    color: #f0e6e9;
}
.keyboard-under-title{
    color: #ecdfe2;
    opacity: 0.6;
}