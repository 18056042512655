.banner-back-half-cicle-imgae {
    width: 4%;
    margin-bottom: -70px;
    margin-left: -35px;

}

/* Mobile View */
@media screen and (max-width: 992px) {
    .banner-back-half-cicle-imgae  {
       display: none;
    }
}