@import url(../../App.css);

/* .social-form-page-contianer {} */

.sf-banner-contianer {
    margin-left: var(--nonActiveNavWidth);
    padding: 2em 6em 7em 6em;
    cursor: url('../../assets/images/cursor.png'), auto;
}

.banner-form-contianer {
    padding-left: 92px;
}

.banner-top-2-fields-continaer {
    display: flex;
    column-gap: 40px;
}

.cursor {
    position: relative;
    width: 100%;
    cursor: url('../../assets/images/cursor.png'), auto !important;
}

.cursor i {
    position: absolute;
    width: 5px;
    height: 70%;
    background-color: var(--white);
    left: 20px;
    top: 15%;
    animation-name: blink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    opacity: 1;
    cursor: url('../../assets/images/cursor.png'), auto !important;
}

.cursor input:focus+i {
    display: none;
}

@keyframes blink {

    /* from { opacity: 1; }
    to { opacity: 0; } */
    0% {
        opacity: 1
    }

    25% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    75% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

.banner-input {
    width: 100%;
    background-color: transparent;
    color: var(--white);
    outline: none;
    border: 3px solid var(--white);
    font-size: 60px;
    font-family: var(--gothamBlack);
    border-radius: 10px;
    padding: 10px 20px;
    cursor: url('../../assets/images/cursor.png'), auto;
}

.sf-custom-dropdown {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    background-color: transparent;
    color: var(--white);
    border: 3px solid var(--white);
    font-size: 60px;
    font-family: var(--gothamBlack);
    border-radius: 10px;
    padding: 10px 20px;
    cursor: url('../../assets/images/cursor.png'), auto;
}

.dropdown-slide-contianer {
    margin-top: -8px;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5);
    ;
    font-size: 60px;
    font-family: var(--gothamBlack);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px;
    cursor: url('../../assets/images/cursor.png'), auto;
    transition: all .4s;
    overflow: hidden;
    opacity: 0;
}

.dropdown-slide-contianer:hover {
    color: var(--white);
}

.sf-dropdown-active {
    border-left: 3px solid var(--white);
    border-right: 3px solid var(--white);
    border-bottom: 3px solid var(--white);
    opacity: 1;
}

.sf-sec-2-heading-contianer {
    padding-bottom: 1em;
}

.sf-scc-2-heading {
    text-align: center;
}

.blue-heading {
    color: var(--blue);
}

.sf-sec-3-contianer {
    padding-left: var(--nonActiveNavWidth);
    display: flex;
    padding-bottom: 2em;
}

.af-sec-3-left-contianer {
    width: 50%;
    padding-left: 7em;
    padding-top: 4em;
    padding-bottom: 4em;
    z-index: 1;
}

.sf-sec-3-content{
    margin-top: 3em;
    font-family: var(--gothamLight);
    font-size: 18px;
}

.af-sec-3-right-contianer {
    width: 50%;
    z-index: 999;
}

.sf-sec-3-screen-image{
    width: 100%;
    position: absolute;
    max-width: 591px;
    margin-left: -72px;
    right: 0;
    z-index: 0;
}
.sf-heading{
    padding: 2em 0 0 84px;
    font-family: var(--gothamLight);
}
.sf-form{
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: -28px;
    width: 50%;
}
.sf-sec-3-screen-image2{
    display: none;
}
.sf-sec-3-heading-mv{
    display: none;
}
.sf-cricle{
    position: absolute;
    left: 0;
    margin-top: -11em;
    opacity: 0.2;
    transform: rotate(180deg);
}
.sf-cricle-third{
    position: absolute;
    right: 0;
    margin-top: -32em;
    opacity: 0.4;
    width: 400px;
}
.sf-cricle-sec{
    position: absolute;
    margin-top: 10em;
    opacity: 0.4;
    width: 400px;
    transform: rotate(37deg);
    right: 172px;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .sf-banner-contianer{
        margin-left: unset;
        padding: 2em 2em 0 2em;
    }
    .sf-heading {
        padding: 2em 0 0 0em;
        font-size: 18px;
        letter-spacing: 5px;
    }
    .banner-form-contianer{
        padding-left: unset;
    }
    .banner-top-2-fields-continaer{
        flex-direction: column;
    }
    .sf-form{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        margin-top: unset;
        transform: rotate(10deg);
    }
    .sf-sec-3-contianer{
        padding: unset;
        flex-direction: column;
    }
    .af-sec-3-left-contianer{
        width: unset;
        padding: 30px 40px;
    }
    .product-pages-heading{
        font-size: 23px;
    }
    .sf-sec-3-screen-image{
        display: none;
    }
    .sf-sec-3-screen-image2{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .banner-input{
        font-size: 34px;
    }
    .cursor{
        padding-top: 1em;
    }
    .cursor i{
        top: 24%;
    }
    .sf-custom-dropdown{
        align-items: flex-end;
        font-size: 27px;
    }
    .dropdown-slide-contianer{
        font-size: 27px;
    }
    .sf-sec-2-heading-contianer{
        padding-top: 3em;
    }
    .sf-sec-3-heading{
        display: none;
    } 
    .sf-sec-3-heading-mv{
        display: unset;
    }
    .sf{
        width: 8%;
    }
    
}