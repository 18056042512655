@import url(../../App.css);

.c-p-banner-contianer {
    padding-left: var(--nonActiveNavWidth);
}

.c-p-banner-sections {
    padding-top: 8em;
    display: flex;
}

.c-p-banner-image {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 650px;
}

.c-p-banner-heading {
    width: 53%;
    padding-left: 40px;
}

.c-p-banner-image-container {
    width: 50%;

}

.c-p-section-2-contianer {
    text-align: center;
    padding: 2em 0;
}

.c-p-section-3-contianer {
    margin-top: 4em;
    padding-bottom: 2em;
}

.c-p-sec-3-heading {
    text-align: center;
    font-weight: 600;
    font-size: 38px;
}

.c-p-key-points-contianer {
    margin-top: 7em;
    display: flex;
    column-gap: 6em;
    justify-content: center;
}

.c-p-key-point-contianer{
    max-width: 180px;
}

.c-p-point-circle-contianer {
    height: 130px;
    width: 130px;
    background-color: var(--white);
    border-radius: 50%;
    margin: auto;
}
.c-p-point-image{
    height: 90px;
    width: 90px;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.c-p-point-heading {
    color: var(--white);
    font-size: 18px;
    font-family: var(--gothamMedium);
    text-align: center;
    margin-top: 1em;
    font-weight: 300;
}

.c-p-point-dis {
    font-size: 13px;
    color: var(--white);
    font-family: var(--gothamLight);
    text-align: center;
    width: 170px;
    margin-left: auto;
    font-weight: 600;
    line-height: 22px;
}

.careers-card-contianer{
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    row-gap: 2em;
}
.carrerdot{
    max-width: 499px;
    z-index: 0;
    opacity: 1.4;
    transform: rotate(-351deg);
    transform-origin: 300% 790%;
}
.dot-halfimage{
    display: none;
}
.career-arrow{
    margin-left: 72px;
}
.career-head{
    font-size: 44px;
}
.career-button{
    cursor: pointer;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    background-color: transparent;
    height: 34px;
    width: 120px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--gothamLight);
}
.career-button:hover{
    background: var(--labelGradint);
    border: none;
}
.career-head{
    padding-top: 2em;
}


/* Mobile View */
@media screen and (max-width: 992px) {
    .c-p-banner-contianer {
        padding-left: unset;
    } 
    .c-p-banner-sections {
        padding-top: 0;
    }
    .c-p-banner-heading{
        width: unset;
    }
    .c-p-banner-image-container{
        width: unset;
    }
    .c-p-banner-image{
        width: 50%;
        margin-top: 14em;
    }
    .c-p-section-2-contianer{
        padding: 8em 40px 30px 40px;
        text-align: left;
    }
    .c-p-key-points-contianer{
        column-gap: 6%;
        padding: 30px 30px;
        margin-top: 0;
    }
    .c-p-point-circle-contianer {
        height: 60px;
        width: 60px;
    }
    .c-p-point-heading{
        font-size: 16px;
        font-weight: bolder;
        margin-bottom: 0;
    }
    .c-p-point-dis{
        font-size: 12px;
        width: 60px;
        margin-left: unset;
    }
    .careers-card-contianer{
        margin-top: unset;
    }
    .dot-halfimage{
        position: absolute;
        margin-top: -15em;
        display: unset;
        width: 87%;
    }
}
