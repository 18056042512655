@import url(../../App.css);

.footer-contianer {
    padding: 4em;
}

.footer-sections-contianer {
    display: flex;
    justify-content: space-between;
    color: var(--white);
}

.footer-logo-image {
    max-width: 200px;
}

.footer-company-address-details {
    font-size: 15px;
    font-family: var(--gothamLight);
    margin-top: 1.5em;
}

.footer-links-contianer {
    display: flex;
    line-height: 5px;
    margin-top: 2em;
}

.footer-left-links-container {
    display: flex;
    flex-direction: column;
    width: 200px;
}

.footer-right-links-container {
    display: flex;
    flex-direction: column;
    width: 200px;
    position: relative;
    z-index: 999;
}

.footer-link {
    font-size: 15px;
    font-family: var(--gothamLight);
    text-decoration: unset;
    color: var(--white);
    cursor: pointer;
    padding: 20px 0;
}

.footer-bottom-container{
    text-align: center;
    margin-top: 2em;
}

.footer-bottom-text{
    font-size: 15px;
    font-family: var(--gothamLight);
    color: var(--white);
}
.footer-contianer-mobile{
    display: none;
}
.exp-footer{
    line-height: 18px;
}


/* Mobile View */
@media screen and (max-width: 992px) {
    .footer-sections-contianer{
        flex-direction: column-reverse;
        padding: 15px 40px;
    }
    .footer-contianer{
        padding: 30px 40px;
        display: none;
    }
    .footer-bottom-container{
        text-align: left;
        padding: 0px 6em 0 40px
    }
    .footer-contianer-mobile{
        padding: 0px 40px;
        display: unset;
    }
    .footer-logo-image{
        padding-top: 2em;
    }
    .footer-company-address-details{
        padding-right: 3em;
    }
    .footer-link{
        padding: 14px 0;
    }
}