@import url(../../App.css);

.tap-banner-continaer {
    padding-left: var(--nonActiveNavWidth);
}

.tap-banner-center-align {
    padding-top: 7em;
    display: flex;
    max-width: 1000px;
    margin: auto;
}

.tap-heading-continaer {
    width: 650px;
    padding-top: 50px;
}

.tap-explore-but-div-contianer {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
}

.tap-banner-icon {
    width: 80px;
    margin-top: -3em;
}

.tap-banner-image-contianer{
    position: relative;
}

.tap-banner-image {
    max-width: 700px;
    position: absolute;
    left: -12em;
}

.tap-sec-2-contianer{
    margin-left: var(--nonActiveNavWidth);
    display: flex;
    padding-top: 10em;
    padding-bottom: 4em;
    column-gap: 5%;
    padding-right: 4em;
}

.sec-2-image{
    width: 53%;
    /* max-width: 900px; */
}

.sec-2-content-contianer{
    margin: auto 0;
    /* max-width: 400px; */
}

.tap-sec-2-para{
    margin-top: 2em;
    font-family: var(--gothamLight);
    font-size: 14px;
}

.tap-head{
    font-family: var(--gothamLight);
}

.tap-arrow{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6em;
}
.tap-content-head{
    font-size: 50px;
}
.halfcircle-td{
    position: absolute;
    right: 0;
    width: 600px;
    opacity: 0.4;
}
.tap-cricle{
    position: absolute;
    right: 0;
    margin-top: -6em;
    opacity: 0.4;
}