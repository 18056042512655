@import url(../../App.css);


.about-us-contianer {
    /* background: url("../../assets/images/WhyHome.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover; */
    padding-bottom: 9em;
}

.abot-sec-2-container {
    display: flex;
    margin-bottom: 4em;
}

.about-left-sec2-container {
    width: 60%;
}

.about-right-sec2-container {
    width: 40%;
}

.about-subhead{
    font-size: 39px;
}

.about-right-para {
    max-width: 500px;
    font-family: var(--gothamLight);
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
}

.about-right-para-top-margin {
    margin-top: 2em;
    font-family: var(--gothamLight);
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
}

.scroll-div-para-container {
    margin-top: 4em;
    max-width: 500px;
    height: 100px !important;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 10px;
}

.scroll-div-para {
    width: 3280px;
    font-family: var(--gothamLight);
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
}

.about-right-sec2-arrows-container {
    display: flex;
}

.about-sec-2-arrows {
    font-size: 50px;
    color: var(--white);
    cursor: pointer;
}

.about-sec-2-arrows {
    margin-right: 20px;
}

.about-us-image {
    max-width: 50%;
    margin-top: -13em;
    position: absolute;
}

.about-sec3-top-bottom-padding {
    padding: 4em 4em;
    background-image: url('../../assets/images/blue_bg_img.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 33%;
}

.about-sec-3-right-heading {
    font-size: 22px;
    color: var(--white);
    font-family: var(--gothamLight);
    font-weight: bolder;
    line-height: 24px;
}
.about-sec-3-right-content{
    margin-top: 2em;
    font-family: var(--gothamLight);
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
}

.bs-margin-auto{
    margin: 6em 0 0 7em !important;
}
.aboutus-animated {
    top: 123em;
    left: -120px;
    z-index: 0;
    position: absolute;
}
.row{
    flex-wrap: unset !important;
    margin-left: 5em !important;
}
.right-halfimage{
    display: none;
}
.aboutus-arrow{
    margin-right: 5vh;
}
.dot-right{
    position: absolute;
    right: 0;
    margin-top: -40em; 
}
.about-col-head{
    font-size: 39px;
    padding-left: 2em;
}


/* .col-md-6{
    width: 40% !important;
}
.row>* {
    padding-left: 6em !important;
    padding-left: calc(var(--bs-gutter-x) * 1.5) !important;
}
.aboutblue{
    margin-left: 5em !important;
}  */

/* Mobile View */
@media screen and (max-width: 992px) {
    .about-left-sec2-container {
        width: 10%;
    }   
    .about-right-sec2-container {
        width: 80%;
        margin-top: 10em;
    }
    .scroll-div-para {
        font-size: 18px;
    }
    .about-sec3-top-bottom-padding{
        padding: 0;
    }
    .about-sec-3-right-heading{
        margin-top: 3em;
        width: unset;
        font-size: 17px;
    }
    .about-sec-3-right-content{
        margin-top: 3em;
        width: unset;
    }
    .about-us-image{
        max-width: 70%;
    }
    .row{
        flex-direction: column !important;
        margin-left: unset !important;
    }
    .bs-margin-auto{
        margin: unset !important;
    }
    .aboutus-animated{
        display: none !important;
    }
    .right-halfimage{
        width: 40%;
        display: block;
        margin-left: auto;
        margin-top: -7em;
    }
    .about-dot{
        margin-top: 8em !important;
    }
    .dot-right{
       display: none;
    }
}