@import url(../../App.css);

.what-makes-c-container {
    padding: 4em;
}

.wow-contianer {
    color: var(--yellowThemeColor);
    text-decoration: underline;
}

.what-makes-heading {
    text-align: center;
}

.what-makes-c-content {
    max-width: 800px;
    margin: auto;
    position: relative;
}

.what-makes-points-contianer {
    display: flex;
    margin-top: 5em;
}

.what-makes-left-points-contianer {
    width: 50%;
}

.what-makes-right-points-contianer {
    width: 50%;
    border-left: 2px solid var(--white);
    padding-left: 20px;
}

.what-makes-content-heading{
    font-size: 17px;
}

.what-makes-left-list{
    font-size: 17px;
    color: var(--white);
    font-family: var(--gothamLight);
}

.what-makes-left-list-item{
    margin-top: 25px;
}

.what-makes-dh-item-contianer{
    display: flex;
    column-gap: 12px;
    margin-top: 25px;
}

.dh-item-icon{
    width: 17px;
    height: 17px;
    margin-top: 5px;
}

.dh-item-text-p{
    margin: unset;
    font-size: 17px;
    color: var(--white);
    font-family: var(--gothamLight);
}