@import url(../../App.css);

.how-we-page-container {
    /* background: url("../../assets/images/HowHome.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover; */
    /* padding-bottom: 6em; */
    /* padding-left: var(--nonActiveNavWidth); */
}

.how-we-banner-animated-image {
    position: absolute;
    background: url('../../assets/images/howWeAnimatedCircle.png') no-repeat;
    background-position: center;
    background-size: contain;
    width: 700px;
    height: 700px;
    top: 100px;
    left: -200px;
    animation-name: slidein;
    animation-duration: 1s;
    z-index: 0;
}

@keyframes slidein {
    from {
        left: 2000px;
        top: -300px;
    }

    to {
        left: -200px;
        top: 100px;
    }
}

.how-we-heading {
    margin-top: 8em;
    text-align: right;
}

.how-we-content-text {
    margin-top: 2em;
    text-align: right;
}

.how-we-hanglecontext-left {
    width: 60vh;
    display: inline-block;
}
.heading-title{
    line-height: 58px;
}

.how-local-down-arrow-image-class {
    margin-top: 2em;
    margin-right: 17vh;
}

.heading-2-sub-page-heading {
    text-align: center;
}

.tab-view-buttons-container {
    display: flex;
    justify-content: center;
}
.mv-nav-item{
    margin-left: 5em;
}

/* bootstrap tab View Custom class */

.tab-view-nav-class {
    font-family: var(--gothamLight);
    width: 185px;
    background-color: unset !important;
    border-radius: unset !important;
    color: var(--white) !important;
    font-size: 14px !important;
}
.tab-view-nav-class:hover {
    color: var(--white) !important;
}

.tab-bottom-border {
    height: 3px;
    background-color: unset;
    width: 60px;
}

.nav-pills .nav-link.active .tab-bottom-border {
    background-color: var(--white);
}

.nav-pills .nav-link.active {
    font-family: var(--gothamMedium) !important;
}

/* image with the image styles */

.sec4-subtext-container {
    margin-top: 2em;
    font-family: unset;
    font-weight: 500;
}

.heading-with-image-container {
    display: flex;
    padding-left: unset;
}

.heading-with-image-container .sec-heading-container {
    width: 60%;
    margin: 4em 4em;
    padding-left: 13em;
}

.heading-with-image-container .sec-image-container {
    width: 50%;
}

.computer-image-container {
    width: 100%;
    /* max-width: 450px; */
    margin-top: -6em;
}
.tab-content{
    padding: 3em 10em;
}
.heading-with-image-tab-container{
    display: flex;
    gap: 4%
}
.sec-heading-tab-container{
    width: 40%;
    padding-top: 0em;
}
.tab-content-heading{
    text-transform: uppercase;
}
.tab-content-heading-nav{
    color: #ECA578;
}
.tab-message{
    padding: 40px 90px;
}
.chat-shape-lady-image-tab{
    position: absolute;
    z-index: 2;
    width: 4%;
    margin: 26px 0px 0 24px;
}
.tab-nav-content{
    padding: 40px 40px 40px 10em;
}
.tab-nav-messaging{
    display: flex;
    flex-direction: column;
    gap: 20%;
    padding-top: 2em;
}
.tab-message-content{
    justify-content: center;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
}
.tab-second{
    text-align: center;
    padding-top: 6em;
}
.tab-switch-sec{
    font-size: 24px;
    padding: 0 0 0 5em;
}
.tab-third-para{
    font-size: 24px;
    padding: 6rem 0 0 2em;
}
.tab-switch-third{
    font-size: 24px;
    padding-right: 10em;
    text-align: center;
}
.tab-switch-four{
    font-size: 24px;
    text-align: center;
}
.howwe-back{
    padding-left: 10em;
}
.howwe-tab{
    width: 390px;
}
.mv-tab-content{
    display: none;
}
.mv-tab-content-howwe{
    display: none;
}
.banner-input-whywe{
    width: 115%;
    background-color: transparent;
    color: var(--white);
    outline: none;
    border: 3px solid var(--white);
    font-size: 20px;
    font-family: var(--gothamLight);
    border-radius: 10px;
}
.banner-input-whywe-third{
    width: 30%;
    background-color: transparent;
    color: var(--white);
    outline: none;
    border: 3px solid var(--white);
    font-size: 20px;
    font-family: var(--gothamLight);
    border-radius: 10px;
}
.third-button:hover{
    background-color: #FFFFFF;
    color: black;
    font-weight: 600;
    font-family: var(--gothamMedium);
}
.tab-nav-messaging-third{
    width: 50%;
    padding-top: 3em;
}
.tab-third{
    display: flex;
    gap: 2%;
    padding-top: 1em;
}
.tab-third-sec{
    display: flex;
    gap: 3%;
    padding-top: 10px;
}
.dob{
    width: 20%;
}
.job{
    width: 41%;
}
.button{
    background-color: grey;
    font-family: var(--gothamBlack);
}
input{
    padding: 1px 22px;
}
::placeholder {
    color: white;
    opacity: 0.4; 
    font-size: 16px;
  }
.cursor-tab {
    position: relative;
    width: 100%;
}

.cursor-tab i {
    position: absolute;
    width: 3px;
    height: 42%;
    background-color: var(--white);
    left: 50px;
    top: 30%;
    animation-name: blink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    opacity: 1;
}

.cursor-tab input:focus+i {
    display: none;
}
.cursor-tab-third {
    position: relative;
    width: 100%;
}

.cursor-tab-third i {
    position: absolute;
    width: 3px;
    height: 30%;
    background-color: var(--white);
    left: 15px;
    top: 50%;
    animation-name: blink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    opacity: 1;
}

.cursor-tab-third input:focus+i {
    display: none;
}
.tab-sec{
    padding-top: 1em;
    margin-bottom: 0;
    opacity: 0.4;
    font-family: var(--gothamLight);
}
.tab-nav-messaging-sec{
    padding-top: 1em;
}
.tab-first-heading{
    padding-top: 5em;
}
.searchi{
    position: absolute;
    top: 10px;
    left: 13px;
    width: 18px;
}
.mail-heading{
    font-family: var(--gothamLight);
    font-size: 12px;
    color: white;
    font-weight: 300;
}
.mail-heading-para{
    font-weight: 600;
    font-size: 18px;
    color: white;
    padding-left: 2em;
}
.mail-heading-para2{
    font-weight: 600;
    font-size: 14px;
    color: white;
    padding-left: 23px;
}
.mail-heading-description{
    font-weight: 325;
    font-size: 17px;
    color: white;
    margin-bottom: 0 !important;
}
.tab-four-para{
    text-align: center;
}
.one-para{
    line-height: 22px;
}
.sec-para{
    line-height: 22px;
    margin-top: 15px;
}
.third-para{
    line-height: 22px;
    margin-top: 15px;
}
.four-para{
    margin-top: 15px;
    margin-bottom: 0 !important;
}
.howwe-paragraph{
    font-family: var(--gothamLight);
    font-weight: 600;
}
.chat-heading-click-head{
    font-size: 18px;
    font-family: var(--gothamMedium);
    color: var(--white);
}
.chat-heading-click-desc{
    font-size: 14px;
    font-family: var(--gothamLight);
    color: var(--white);
}
.mail-heading-para-item1{
    background-color: #FFFFFF !important;
    color: #E9406A !important;
    border-radius: 15px !important;
    padding: 0px 12px 2px !important;
    font-weight: 600 ;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    font-family: var(--gothamMedium) !important;
    text-transform: none !important;
}
.css-3bmhjh-MuiPaper-root-MuiPopover-paper{
    background: transparent !important;
    border-radius: 20px !important;
    width: 270px;
}
.video-image{
    width: 100%;
}
.video-para{
    font-family: var(--gothamMedium);
    font-size: 16px;
    padding-top: 1em;
    color: #FFFFFF;
}
.video-desc{
    font-family: var(--gothamMedium);
    font-size: 14px;
    color: #FFFFFF;
}
.vectorvideo{
    margin-right: 8px;
}



/* Mobile View */
@media screen and (max-width: 992px) {
    .heading-with-image-container{
        padding-left: unset;
        flex-direction: column;
    }
    .heading-with-image-container .sec-image-container {
       margin-left: 10em;
    }
    .how-we-hanglecontext-left {
        width: unset;
    }
    .how-we-banner-animated-image{
        width: unset;
    }
    .how-local-down-arrow-image-class {
        margin-right: 3vh;
    }
    .how-we-heading {
        margin-top: 0;
    }
    .heading-with-image-container .sec-heading-container {
        padding: 30px 40px;
        width: unset;
        margin: unset;
    }
    .howwe-tab{
        width: unset;
    }
    .computer-image-container{
        margin-top: -4em;
    }
    .banner-dot-bottom-howwe{
        display: none;
    }
    .tab-content{
        padding: unset;
    }
    .heading-with-image-tab-container{
        flex-direction: column;
    }
    .sec-heading-tab-container{
        width: unset;
    }
    .nav-pills .nav-link.active .tab-bottom-border{
        display: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        background-color: #8E2164 !important;
        border-radius: 68px !important;
        font-size: 12px !important;
        width: 170px; 
        color: white !important;
    }
    .chat-shape-lady-image-tab{
        width: 20%;
        padding: 2em 1em 1em 1em;
    }
    .mv-howwe{
        font-size: 12px !important;
        margin-top: 2em;
    }
    .sec-heading-tab-container{
        display: none;
        padding-top: 3em;
    }
    .mv-tab-content{
        display: unset;
    }
    .tab-nav-messaging{
        padding-top: unset;
    }
    .mv-tab-content-switch{
        font-size: 16px;
        align-items: center;
        margin-top: 0 !important;
    }
    .mv-tab-content-howwe{
        padding-top: unset;
        display: unset;
        width: 190px;
    }
    .whywe-dot{
        margin-top: unset;
    }
    .tab-view-buttons-container {
        flex-wrap: unset !important;
        overflow-x: scroll;
    }
    .mv-nav-item{
        margin-left: 31em;
    }

}