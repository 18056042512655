@import url(../../App.css);

.shadow-box-border-wraper-st {
    position: relative;
    padding: 20px 15px;
    margin: 5px;
    display: inline-block;
    font-size: 30px;
    background-image: linear-gradient(to bottom right, transparent, rgba(255, 255, 255, .2));
    border-radius: 20px;
}

.shadow-box-border-wraper-st::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 1px;
    background: linear-gradient(10deg, transparent, white);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .shadow-box-border-wraper-st {
        margin: 0px;
    }
}