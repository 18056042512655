.banner-dot-half-cicle-imgae {
    max-width: 570px;
    position: absolute;
    right: 0;
    z-index: 0;
    bottom: 0;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .banner-dot-half-cicle-imgae  {
        max-width: 200px !important;
        bottom: -3em;
    }
}