@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");


@font-face {
  font-family: 'gotham-bold';
  src: url('./assets/fonts/gotham/GothamBold.ttf') format('truetype');
  src: url('./assets/fonts/gotham/GothamBold.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-black';
  src: url('./assets/fonts/gotham/GothamBlack.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-medium';
  src: url('./assets/fonts/gotham/GothamMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'gotham-light';
  src: url('./assets/fonts/gotham/GothamLight.ttf') format('truetype');
  src: url('./assets/fonts/gotham/GothamLight.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-bookitalic';
  src: url('./assets/fonts/gotham/GothamBookItalic.ttf') format('truetype');
  src: url('./assets/fonts/gotham/GothamBookItalic.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-thin';
  src: url('./assets/fonts/gotham/GothamThin.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-narrow-thin';
  src: url('./assets/fonts/gotham/GothamNarrowThin.otf') format('opentype');
}

:root {
  --navWidth: 200px;
  --nonActiveNavWidth: 150px;
  --white: #FFFFFF;
  --black: #000000;
  --blue: #164AD0;
  --lightBlue: #758BFD;
  --yellowThemeColor: #ECA578;
  --redThemeColor: #ee2e67;
  --darkPink: #8F2168;
  --gothamBold: "gotham-bold";
  --gothamMedium: "gotham-medium";
  --gothamLight: "gotham-light";
  --gothamBookItalic: "gotham-bookitalic";
  --gothamBlack: "gotham-black";
  --gothamThin: "gotham-thin";
  --GothamNarrowThin: "gotham-narrow-thin";
  --labelGradint: linear-gradient(88.9deg, #8E2164 -3.09%, #E83768 46.42%, #EFAB7A 100.81%), linear-gradient(180deg, #164AD0 0%, rgba(22, 74, 208, 0) 100%);
  --pageHeadingSize: 60px;
  --pageSubHeadingSize: 35px;
  --pageMedHeading: 50px;
  --pageSubHeading: 14px;
  --blue-BG: linear-gradient(335.09deg, #3a65d0 4.6%, rgba(255, 255, 255, 0) 100.02%);
  --voilet-BG: linear-gradient(159.12deg, #913c73 6.2%, rgba(255, 255, 255, 0) 148.45%);
  --orange-BG: linear-gradient(275.11deg, #ECA578 11.11%, rgba(255, 255, 255, 0) 167.69%);
  --theme-background-element: linear-gradient(88.9deg, #8E2164 -3.09%, #E83768 46.42%, #EFAB7A 100.81%), linear-gradient(180deg, #164AD0 0%, rgba(22, 74, 208, 0) 100%);
}

.theme-heading {
  font-size: 90px;
  font-family: var(--gothamThin);
  text-align: center;
  margin: 23px;
  font-weight: 100;
}

.pageHeading {
  font-size: var(--pageHeadingSize);
  color: var(--white);
  font-family: var(--gothamBlack);
  margin: unset;
}

.pageHeadingMed {
  font-size: var(--pageMedHeading);
  color: var(--white);
  font-family: var(--gothamBlack);
  margin: unset;
}

.pageSubHeading {
  font-size: var(--pageSubHeadingSize);
  color: var(--white);
  font-family: var(--gothamBlack);
  margin: unset;
}

.product-pages-heading {
  font-size: 50px;
  color: var(--white);
  font-family: var(--gothamBlack);
  margin: unset;
}

.pageSmallText {
  font-size: var(--pageSubHeading);
  color: var(--white);
  font-family: var(--gothamMedium);
  font-weight: 400;
  line-height: 1.5;
}

.down-arrow-image {
  width: 15px;
}

.blueSecBG {
  background-color: var(--blue);
}

.lightBlueSecBG {
  background-color: var(--lightBlue);
}

.margin-vertical-center {
  margin: auto 0 !important;
}

.blue-lin-grad-BG {
  background: var(--blue-BG)
}

.blue2-lin-grad-BG {
  background: var(--blue-BG);
  /* left: -60px;
  top: -134px; */
  /* margin-top: 12em !important; */
}

.voilate-lin-grad-BG {
  background: var(--voilet-BG);
  /* top: -134px; */
  /* margin-top: 12em !important; */
}

.orange-lin-grad-BG {
  background: var(--orange-BG);
  /* left: -60px; */
}

.section-bg-margin-left {
  padding-left: var(--nonActiveNavWidth);
}

.product-page-name-heading {
  font-family: var(--GothamNarrowThin);
  font-size: 30px;
  color: var(--white);
  letter-spacing: 10px;
  font-weight: 500;
}

.section-popup{
  border-radius: 30px;
}

/*media query for mobile screen*/

@media screen and (max-width: 992px) {
  .pageHeading {
      font-size: 25px;
  }
  .pageSmallText{
    font-size: 12px;
    font-weight: 350;
  }
  .pageSubHeading{
    font-size: 25px;
  }
  .theme-heading{
    font-size: 40px;
  }
  .section-bg-margin-left {
    padding-left: unset;
  }
  .theme-heading{
    margin: 0;
  }
}