@import url(../../App.css);

.com-heading{
    text-align: center;
    text-transform: uppercase;
}

.center-heading{
    margin-top: 1.5em;
    color: #0B0C1F;
}

.sec-option-container{
    margin-top: 8em;
}
.whywe{
    gap: 3%; 
}
.option-whywe{
    margin-left: -20px;
}
.sec-content{
    width: 100px;
}
.opt-whywe{
    display: contents;
    color: #0B0C1F;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .opt-whywe{
        display: flex;
        gap: 3%;
        align-items: center;
        padding: unset;
    }
    .option-whywe{
        margin-left: unset
    }
    .sec-content{
        width: unset;
    }
}