@import url(../../App.css);

.parent-selector-contianer {
    display: flex;
    column-gap: 20px;
    min-height: 350px;
}

.parent-options-contianer {
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    row-gap: 20px;
}

.contact-sections-container {
    display: flex;
    flex-direction: column;
}

.parent-option-contianer {
    display: flex;
    column-gap: 10px;
    cursor: pointer;
}

.parent-outer-circle {
    width: 30px !important;
    height: 25px !important;
}

.message-text-field-container {
    padding: 20px;
    background: transparent;
    color: var(--white);
    text-transform: uppercase;
    outline: unset;
    border: 1px solid var(--white);
    border-radius: 20px;
    font-family: var(--gothamLight);
}

.message-text-field-container::placeholder {
    color: var(--white);
    opacity: 1;
    font-family: var(--gothamLight);
}

.message-screen-contianer {
    width: 100%;
    max-width: 500px;
}

.contact-get-touch-heading {
    max-width: 900px;
    display: flex;
    margin-bottom: 4em;
}

.contact-us-head-section-heading {
    margin: auto 0;
    padding-left: 1em;
    color: var(--yellowThemeColor);
}

.get-touch-name-container {
    color: var(--redThemeColor);
    text-transform: uppercase;
}

.career-getintouch{
    padding: 3em 10em 0 10em !important;
}

/* constom stepper class */
.stepper-contianer {
    /* width: 230px; */
    display: flex;
}

.radio-and-line-continaer {
    display: flex;
}

.stepper-step-content-container {
    display: flex;
    flex-direction: column;
    width: 140px;
}

.step-label-contianer {
    text-align: left;
    width: 100%;
    font-size: 16px;
    font-family: var(--gothamBold);
    color: var(--white);
    margin-bottom: 15px;
}

.step-label-contianer-bottom-border {
    border-bottom: 3px solid var(--white);
}

.step-icon-contianer {
    width: 25px;
}

.outer-circle {
    background: linear-gradient(319.24deg, rgba(255, 255, 255, 0.4) -4.1%, rgba(255, 255, 255, 0) 101.39%);
    border: 0.1px solid #FFFBFB;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    position: relative;
    cursor: pointer;
}

.inner-circle {
    position: absolute;
    background: transparent;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    top: 50%;
    left: 50%;
    margin: -7px 0px 0px -7px;
}

.inner-circle-selected {
    background: #F3F4C2;
}

.inner-parent-circle-selected {
    background: var(--darkPink);
}

.inner-circle-has-data {
    background: var(--redThemeColor);
}

.between-stepper-steps {
    /* border-right: 1px solid var(--white);
    margin-right: 12.5px; */
    background-color: var(--white);
    height: 1px;
    margin: auto;
    width: 100%;
}

/* contact rest all content contianer */

.container-text-content-container {
    width: 100%;
    margin-top: 2em;
}

.constact-text-fields-container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}

.contact-info-contianer {
    background-color: transparent;
    padding: 8px;
    color: var(--white);
    width: 100%;
    max-width: 700px;
    text-transform: uppercase;
    font-family: var(--gothamBold);
    outline: none;
    border: unset;
    font-size: 15px;
    border-radius: 15px;
}

.contact-info-contianer::placeholder {
    color: var(--white);
    opacity: 1;
    font-family: var(--gothamLight);
}

.contact-info-contianer:-ms-input-placeholder {
    color: var(--white);
}

.contact-info-contianer::-ms-input-placeholder {
    color: var(--white);
}

.active-state-text-field {
    border: 2px solid #FFFBFB;
}

.get-in-touch-button-contianer {
    margin-top: 3em;
}

/* card view container */
.get-in-touch-card-view-container {
    padding: 20px;
    border-radius: 20px;
    background-color: var(--white);
    max-width: 600px;
    display: flex;
}

.contact-user-card-image {
    max-width: 100px;
    height: 100%;
}

.user-prifile-details-cotnainer {
    margin-left: 20px;
    color: var(--redThemeColor)
}

.contact-user-profile-card-contact-container {
    margin-top: 2em;
}

.contact-user-profile-card-username {
    font-family: var(--gothamBlack);
    text-transform: uppercase;
    margin: unset;
}

.contact-user-profile-card-jobtitle {
    font-family: var(--gothamMedium);
    font-size: 16px;
    text-transform: uppercase;
}
.sf-sec-circle{
    /* position: relative; */
    z-index: 999;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .contact-get-touch-heading {
        height: 80px;
    }
    .contact-us-head-section-heading{
        font-size: 15px;
    }
    .get-in-touch-section-custom{
        padding: unset;
    }
    .parent-options-contianer{
        display: none !important;
    }
    .contact-sections-container{
        overflow-x: scroll;
    }
    .get-in-touch-card-view-container{
        flex-direction: column;
    }
}