.backicon-td {
    width: 4%;
    margin-bottom: -115px;
    margin-left: 70px;

}

/* Mobile View */
@media screen and (max-width: 992px) {
    .backicon-td  {
       display: none;
    }
}