@import url(../../App.css);

.carrer-teamcard-component {
    width: 48%;
    height: 249px;
    border-radius: 30px;
    margin: auto;
    display: flex;
    background:#FFFFFF;
}

.cc-teamcard-right-container {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.cc-bullet-point {
    display: flex;
    column-gap: 20px;
    margin-top: 1em;
}

.cc-bullet-circle{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--white);
}

.cc-teamoverall-text{
    font-size: 30px;
    font-family: var(--GothamNarrowThin);
    color: #164AD0;
}
.firstheading{
    font-size: 20px;
    font-weight: bolder;
    font-family: var(--gothamMedium);
}
.secondheading{
    font-size: 20px;
    font-family: var(--gothamLight);
}

.cc-teamoverall-text-para{
    line-height: 20px;
    font-size: 14px;
    font-family: var(--gothamLight);
}

.cc-viewall-button{
    cursor: pointer;
    border: 3px solid #164AD0;
    border-radius: 10px;
    background-color: transparent;
    height: 30px;
    width: 120px;
    color: #164AD0;
    font-size: 10px;
    font-weight: 600;
    font-family: var(--gothamMedium);
    letter-spacing: 1px;
}

.modal__backdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}

.modal__container {
	border-radius: 30px;
    margin: 50px auto;
    padding: 0em 16em;
}

.button-popup {
	background: var(--blue);;
	border: 0;
	border-radius: 10px;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 30px;
	padding: 10px 15px;
	transition: transform 100ms ease-out;
}
.button-popup:hover {
    transform: scale(1.1);
}

.modal-content{
    border: unset !important;
    background-color: unset !important;
}
.cc-teamcard-button{
    display: flex;
    flex-direction: column;
    padding: 2em;
    justify-content: space-evenly;
}
.career-card-head{
    font-size: 45px;
}
.career-teamimage{
    width: 250px;
    height: 250px;
}
.career-teamcard{
    display: flex;
    padding: 2em 10em 0 19em;
    position: relative;
}
.teamcard-2{
    padding: 1em 10em 0 19em;
}
.teamcard-3{
    padding: 1em 10em 0 19em;
}
.c-p-section-4-contianer{
    text-align: center;
    padding-bottom: 3em;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .cc-card-right-container {
        display: none;
    }
    .cc-card-left-container{
        width: unset;
        padding: 15px 15px;
    }
    .careers-card-contianer{
        padding: 30px 40px;
    }
    .carrer-teamcard-component{
        padding: unset;
        flex-direction: column;
    }
    .cc-overall-text{
        font-size: 20px;
    }
    .cc-bullet-circle{
        margin-top: 14px;
        width: 23px;
        height: 23px;
    }
    .cc-bullet-point{
        column-gap: 6px;
    }
    .applynow-button{
        text-align: center;
        padding-bottom: 1em;
    }
    .cc-apply-button{
        padding: 5px 20px;
    }
}
