.banner-bottom-howwe-imgae {
    position: absolute;
    right: 0;
    z-index: 0;
    display: block;
    transform: rotate(0deg);
    margin-left: auto;
    margin-top: -758px;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .banner-bottom-howwe-imgae  {
        padding-top: 445px;
        width: 40%;
    }
}