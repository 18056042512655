.banner-bottom-image {
    /* position: absolute; */
    right: 0;
    z-index: 0;
    margin-left: auto;
    display: block;
    transform: rotate(0deg);
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .banner-bottom-image {
        margin-top: -12em;
        width: 200px;
        margin-left: 120px;
        margin-right: auto;
        display: unset;
        padding: unset;
        transform: unset;
    }
}