@import url(../../App.css);

.carousel-component-contianer {
    padding-left: var(--nonActiveNavWidth);
    background-color: var(--blue);
    background-image: url('../../assets/images/blue_bg_img.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 48%;
}

.carousel-component-inner {
    padding: 4em;
    padding-bottom: 1em;
    display: flex;
    column-gap: 2em;
}

.carousel-left-continaer {
    width: 30%;
    display: flex;
    align-items: center;
}

.carousel-contianer {
    width: 70%;
}

.carosel-item-container {
    padding: 0px 20px;
}

.carosel-item-content-container {
    min-height: 200px;
}

.carosel-item-heading {
    font-family: var(--GothamNarrowThin);
    font-size: 25px;
    color: var(--white);
    font-weight: bolder;
}

.carosel-item-content{
    margin-top: 50px;
    font-family: var(--gothamLight);
    font-size: 14px;
    color: var(--white);
}

.carosel-slide-change-container{
    text-align: right;
    font-size: 40px;
    color: var(--white);
    padding-right: 20px;
}

.left-arrow-carousel{
    margin-right: 30px;
}

.carousel-arrow-icon {
    cursor: pointer;
}
.st-title{
    font-size: 45px;
}
.st-wow{
    font-size: 65px;
}
.slick-slide .slick-cloned{
    width: 360px !important;
}