@import url(../../App.css);

.circle-number-component-contianer {
    background-color: var(--yellowThemeColor);
    padding: 4em 2em 4em var(--nonActiveNavWidth);
    background-image: url('../../assets/images/blue_bg_img.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    background-position: 10%;
    align-items: center;
    position: relative;
}

.circle-number-contianer {
    padding: 65px;
    position: relative;
    display: inline-block;
    margin-left: 10em;
}

.outer-circle-for-border {
    width: 390px;
    height: 390px;
    background-color: var(--redThemeColor);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.parent-circle-contianer {
    background: linear-gradient(319.24deg, rgba(255, 255, 255, 0.4) -4.1%, rgba(255, 255, 255, 0) 101.39%);
    backdrop-filter: blur(40px);
    height: 350px;
    width: 350px;
    border-radius: 50%;
    position: relative;
    border: .1px solid var(--white);
    text-align: center;
}

.parent-circle-text-contianer {
    color: var(--white);
    font-family: var(--gothamBlack);
    font-size: 26px;
    line-height: 26px;
    height: 100%;
    padding: 4.5em 2em;
}

.parent-wow-contianer {
    font-size: 60px;
    line-height: 45px;
    color: var(--yellowThemeColor);
}

.parent-ques-contianer {
    color: var(--white);
}

.child-circle {
    position: absolute;
    border: 0.1px solid var(--white);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: linear-gradient(319.24deg, rgba(255, 255, 255, 0.4) -4.1%, rgba(255, 255, 255, 0) 101.39%);
    backdrop-filter: blur(40px);
    text-align: center;
    overflow: hidden;
    cursor: pointer;
}

.clild-image-contianer {
    display: inline-block;
    padding: 19px;
    transition: transform 0.3s ease-in-out;
}

.child-circle-image {
    width: 60px;
}

.child-circle-number-contianer {
    color: var(--white);
    font-size: 65px;
    font-family: var(--gothamBold);
    transition: transform 0.3s ease-in-out;
}

.animate-number {
    transform: translateY(-100%);
}

.animate-image {
    transform: translateY(-100%);
}

.inner-circle-1 {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.inner-circle-2 {
    top: 66px;
    right: 66px;
}

.inner-circle-3 {
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.inner-circle-4 {
    top: 66%;
    left: 66%;
}

.inner-circle-5 {
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.inner-circle-6 {
    bottom: 66px;
}

.circle-number-info-contianer {
    width: 100%;
    margin-left: 3em;
}

.discription-contianer-number-counter {
    transition: transform .5s ease-in-out;
}

.animate-dis-container {
    transform: translateY(8em);
}

.circle-number-heading-contianer {
    border: 3px solid var(--white);
    padding: 10px 20px;
    border-radius: 10px;
    max-width: 400px;
    font-family: var(--GothamNarrowThin);
    color: var(--white);
    font-size: 25px;
    min-height: 43px;
    font-weight: bolder;
}

.circle-number-discription-contianer {
    margin-top: 2em;
    border: 3px solid var(--white);
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    min-height: 200px;
}

.circle-number-discription {
    color: var(--white);
    font-family: var(--GothamNarrowThin);
    font-size: 24px;
    font-weight: 500;
}

.circle-number-title-contianer {
    display: flex;
    max-width: 300px;
    position: absolute;
    font-weight: bolder;
}

.emoji-icon {
    width: 30px;
    height: 30px;
}

.circle-number-title {
    font-size: 25px;
    font-family: var(--GothamNarrowThin);
    color: var(--darkPink);
    margin-left: 20px;
}
.mv-sf{
    display: none;
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .parent-circle-contianer{
        background: unset;
        backdrop-filter: unset;
        height: unset;
        width: unset;
        position: unset;
        border: none;
        text-align: left;
    }
    .circle-number-component-contianer{
        padding: 2em 0 7em 0;
        flex-direction: column;
    }
    .parent-circle-text-contianer{
        padding: unset;
    }
    .child-circle{
        width: 40px;
        height: 40px;
    }
    .outer-circle-for-border{
        width: 120px;
        height: 300px;
        border-radius: 18px;
        margin: 6em 0 0 -8em;
    }
    .circle-number-contianer{
        padding: unset;
    }
    .child-circle-number-contianer{
        font-size: 25px;
    }
    .parent-wow-contianer {
        font-size: 45px;
        color: #758BFD;
    }
    .circle-number-info-contianer {
        width: 70%;
        margin-left: 70px;
    } 
    .mv-sf-head{
        display: none;
    }
    .mv-sf{
        display: unset;
    }  
    .parent-circle-text-contianer{
        font-size: 18px;
    }  
    .circle-number-heading-contianer{
        font-size: 15px;
    }
    .circle-number-discription{
        font-size: 15px;
    }
    .circle-number-title{
        font-size: 15px;
        margin-left: 8px;
        margin-right: 30px;
    }
    .animate-dis-container {
        transform: translateY(6em);
    }
    .inner-circle-1{
        left: -55px;
        margin: unset;
        top: 7em;
    }
    .inner-circle-2{
        top: 157px;
        left: -55px;
    }
    .inner-circle-3{
        top: 223px;
        left: -330px;
    }
    .inner-circle-4{
        left: -57px;
        top: 249px;
    }
    .inner-circle-5{
        top: 315px;
        left: -333px;
    }
    .inner-circle-6{
        left: -57px;
        top: 340px;
    }
    .clild-image-contianer{
        padding: 0;
    }
    .child-circle-image{
        width: 30px;
    }
    .circle-number-heading-contianer{
        padding: 10px 15px;
    }
}