@import url(../../App.css);

.card-details-card-container {
    padding: 20px;
    max-width: 400px;
    width: 100%;
}

.card-details-card-heading-container {
    display: flex;
}

.card-details-card-heading-icon {
    padding: 20px 20px;
    width: 90px;
    height: 90px;
}

.card-details-card-heading {
    font-family: var(--gothamBold);
    font-size: 23px;
}

.card-details-card-subheading {
    font-size: 14px;
    font-family: var(--gothamLight);
}

.card-details-card-discription{
    font-size: 14px;
    font-family: var(--gothamLight);
}

/* Mobile View */
@media screen and (max-width: 992px) {
    .card-details-card-container {
        padding: unset;
    }
    .card-details-card-heading{
        font-size: 16px;
    }
    .card-details-card-subheading{
        font-size: 12px;
    }
    .card-details-card-discription{
        font-size: 12px;
    }
}